import React, { useEffect, useState } from "react";
import styles from "./ExamDropdown.module.css";
// import Dropdown from "react-bootstrap/Dropdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExamDropdownMenu from "./ExamDropdownMenu";
import ExamDDMYearly from "./ExamDDMYearly";
import HYExamService from "../../../../services/hyexam.service";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { fetchExamMarks } from "../../../../slices/hyexam";

function ExamDropdown({ expandedAccordion, setExpandedAccordion }) {
  const { user } = useSelector((state) => state.user);
  const [value, setValue] = useState("all");
  const currentYear = new Date().getFullYear();
  const [DropMenu, setDropMenu] = useState(false);
  const {hyexams, dropDown} = useSelector(state => state.hyexam)
  const [exams, setExams] = useState(hyexams);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  // useEffect(() => {
  //   (async () => {
  //     await getUserExams();
  //   })();
  // }, []);

  // const getUserExams = async () => {
  //   const body = { userid: user.userid, class_id: user.classid, schoolcode: user?.schoolcode }
  //   HYExamService.getUserExamMarks(body)
  //     .then((res) => {
  //       console.log(res, "response");
  //       setData(res);
  //       setExams(res.exams);
  //       setDropDownVal(res.dropDown);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  useEffect(() => {
    setExams(hyexams)
  }, [hyexams])

  const handleChange = (selectedValue) => {
    if (selectedValue === "all") {
      setExams(hyexams);
    } else {
      const filtered = hyexams.filter(
        (exam) => String(exam.examtype) === String(selectedValue)
      );
      setExams(filtered);
    }
    if (!DropMenu) {
      setDropMenu(true);
    }
    setValue(selectedValue);
  };
  const year = new Date().getFullYear(); // returns the current year

  return (
    <div className={styles.main}>
      <Accordion
        className={styles.accord}
        expanded={expandedAccordion === "exam"}
        onChange={handleAccordionChange("exam")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ fontSize: "40px", fontWeight: "normal" }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={styles.examLayout}
        >
          <div className={styles.examdropExam}>
            {/* <div className={styles.headin}>Exam</div> */}
            <Typography className={styles.typo}>Exam</Typography>
            {expandedAccordion === "exam" && <div className={styles.content}>
              <DropdownButton
                id="dropdown-basic-button"
                title={value === "all" ? "All Exams " : `${value}-${year} `}
                onSelect={handleChange}
                onClick={(e) => e.stopPropagation()}
                // style={{backgroundColor: '#6755D9 !important'}}
                // className={styles.styleselect}
              >
                <Dropdown.Item eventKey="all">All Exams</Dropdown.Item>
                {dropDown.map((option) => (
                  <Dropdown.Item key={option} eventKey={option}>
                    {option}-{year}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>}
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <ExamDropdownMenu exams={exams} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ExamDropdown;
