import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Routes from "../Sidebar/Routes";

export default function Student() {
  const { user } = useSelector((state) => state.user);

  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Routes />
    </div>
  );
}
