import React,{useContext} from 'react';
import Routes from '../Sidebar/Routes';


function AccountsLayout() {
  return (
    <div>
        <Routes/>
    </div>
  )
}

export default AccountsLayout