import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "./Layout";
import Profiles from "../Profiles/Profile/Profiles";
import EditProfile from "../Profiles/EditProfile/EditProfile";
import Home from "../Home/Home";
import FillUserInfo from "../AddUser/FillUserInfo";
import Subjects from "../Subjects/Subjects";
import Transport from "../Transport/Transport";
import AcademicFees from "../Expense/AcademicFees/AcademicFees";
import ExtracurricularFees from "../Expense/ExtracurricularFees/ExtracurricularFees";
import TransportFees from "../Expense/TransportFees/TransportFees";
import School from "../School/School";
import TeacherMail from "../../teacher/Mail/TeacherMail";
import AdminCalendar from "../Calendar/AdminCalendar";
import PrincipalNotice from "../../Principal/Notice/PrincipalNotice";
import AdminNotice from "../Notice/AdminNotice";

function Routes() {
  return (
    <Router>
      <Route
        render={(props) => (
          <Layout {...props}>
            <Switch>
              <Route exact path="/admin/home" component={Home} />
              <Route
                exact
                path="/admin/students-profile"
                component={Profiles}
              />
              <Route
                exact
                path="/admin/teachers-profile"
                component={Profiles}
              />
              <Route
                exact
                path="/admin/principal-profile"
                component={Profiles}
              />
              <Route
                exact
                path="/admin/accountant-profile"
                component={Profiles}
              />
              <Route exact path="/admin/staff-profile" component={Profiles} />

              <Route exact path="/admin/edit-profile" component={EditProfile} />
              <Route exact path="/admin/AddUser/" component={FillUserInfo} />
              <Route exact path="/admin/Subjects/" component={Subjects} />
              <Route exact path="/admin/Transport/" component={Transport} />
              <Route exact path="/admin/mail" component={TeacherMail} />
              <Route exact path="/admin/calendar" component={AdminCalendar} />
              <Route exact path="/admin/notice" component={AdminNotice} />



              <Route
                exact
                path="/admin/academic-fees"
                component={AcademicFees}
              />
              <Route
                exact
                path="/admin/extracurricular"
                component={ExtracurricularFees}
              />
              <Route
                exact
                path="/admin/transport-fees"
                component={TransportFees}
              />
              <Route exact path="/admin/school" component={School} />
              <Redirect to="/admin/home" />
            </Switch>
          </Layout>
        )}
      />
    </Router>
  );
}

export default Routes;
