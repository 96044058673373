import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MenuContext } from '../../../context/Menu/MenuContext';
import TimeTable from './TimeTable';
import Circulars from './Circulars';
import Inbox from './Inbox';
import HomeworkAssign from './HomeworkAssign';
import ToDo from './ToDo';
import './TeacherHome.css';
import { useHistory } from 'react-router-dom';

function TeacherHome() {
  const mycontext = useContext(MenuContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory()

  return (
    <div
      onClick={mycontext.offMenu}
      onScroll={mycontext.offMenu}
      className="main__container"
    >
      <div className="left__container">
        <br />
        <span
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '25px',
          }}
        >
          Hello,{' '}
          {user.firstname ? (
            <span
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '25px',
              }}
            >
              {user.firstname}
            </span>
          ) : (
            <span
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '25px',
              }}
            >
              First Name
            </span>
          )}
          &nbsp;<span className="wave">👋</span>
        </span>
        <br />
        <br />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button onClick={() => history.push("student-attendance")} className="tchrbtn">
            Mark Attendance 
          </button>
          <button onClick={() => history.push("add-assignment")} className="tchrbtn">
            Upload Assignments
          </button>
          <button onClick={() => history.push("exam-marks")} className="tchrbtn">
            Upload Marks
          </button>
        </div>
        <TimeTable />
      </div>
      <div
        className="right__container"
        // style={{
        //   width: '76.8%',
        //   height: '61%',
        //   display: 'inline-flex',
        //   position: 'absolute',
        //   top: '5px',
        //   right: 0,
        // }}
      >
        <div
          // style={{
          //   paddingTop: '22px',
          //   paddingLeft: '40px',
          //   paddingRight: '40px',
          // }}
        >
          <div className="div1">
            <Circulars />
            <Inbox />
          </div>
          <div className="div2">
            <HomeworkAssign />
            <ToDo />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeacherHome;
