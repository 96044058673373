import React from 'react';
import './AverageRating.css';

function AverageRating() {
    return (
        <div className='averagerating'>
            <div>
            <h4 style={{margin:'6px 12px',textAlign:'left',color:'#4B4B4B'}}>Average Rating</h4>
            </div>
        </div>
    )
}

export default AverageRating
