import * as React from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { Typography } from "@mui/material";

//dropdown custom box
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    scrollBehavior: "smooth",
    borderRadius: "5px",
    border: "1px solid #414141",
    fontSize: 12,
    color: "#414141",
    padding: "0 0 0 10px",
    "&:focus": {
      borderRadius: "5px",
      borderColor: "none",
      backgroundColor: "#fff",
    },
  },
}));

function ClassDropdown({
  classes,
  getAssignedSubjects,
  getClassIds,
  setClassDropdownValue,
}) {
  //set dropdown values
  const [title, setTitle] = React.useState("");

  //handel the dropdownchange
  const handleChange = (event) => {
    setTitle(event.target.value);
    setClassDropdownValue(event.target.value);

    //get class_code specific data
    getAssignedSubjects([event.target.value]);
  };

  return (
    <FormControl sx={{ my: 1, width: 100, height: 35 }} variant="standard">
      <Select
        value={title}
        onChange={handleChange}
        displayEmpty
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        input={<BootstrapInput />}
      >
        <MenuItem value="">
          <Typography
            sx={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "21px",
            }}
          >
            Class
          </Typography>
        </MenuItem>
        {classes.map((c) => (
          <MenuItem key={c} value={c}>
            {" "}
            <Typography
              sx={{
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              {c}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ClassDropdown;
