import React from "react";

function StatsBox({ text, value }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "10px",
        // border: "1px solid #E3E3E3",
        boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.45)",
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "18px",
          fontFamily: "Roboto, sans-serif",
          color: "rgba(0, 0, 0, 0.6)",
          paddingLeft: "10px",
          paddingTop: "15px",
        }}
      >
        {text}
      </div>
      <hr style={{ width: "100%", margin: "0px" }} />
      <div
        style={{
          fontFamily: "Roboto, sans-serif",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "20px",
          color: "rgba(0, 0, 0, 0.75)",
          padding: "15px 0px 15px 10px",
        }}
      >
        {value}
      </div>
    </div>
  );
}

export default StatsBox;
