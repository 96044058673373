import Routes from '../Sidebar/Routes'

function TeacherLayout() {
    return (
        <div>
            <Routes/>
        </div>
    )
}

export default TeacherLayout
