import React from 'react'

const ExpensesIcon = () => {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1641 37C17.3613 37 19.8366 36.694 21.8222 36.0685C21.7802 35.8426 21.752 35.6117 21.752 35.374C21.752 34.3121 22.1935 33.3534 22.8992 32.6641C22.1935 31.9747 21.752 31.0161 21.752 29.9541C21.752 28.8921 22.1935 27.9335 22.8992 27.2441C22.1935 26.5548 21.752 25.5961 21.752 24.5342C21.752 23.4722 22.1935 22.5136 22.8992 21.8242C22.1935 21.1349 21.752 20.1762 21.752 19.1143C21.752 17.8598 22.3713 16.7541 23.3127 16.063C22.668 15.4727 21.9792 14.9437 21.2565 14.4782C21.5501 14.1085 21.752 13.661 21.752 13.1523C21.752 11.9549 20.7814 10.9844 19.584 10.9844H8.74414C7.54668 10.9844 6.57617 11.9549 6.57617 13.1523C6.57617 13.6599 6.77744 14.1059 7.06933 14.4751C2.86098 17.1862 0 22.0955 0 27.2441C0 35.9891 8.11436 37 14.1641 37ZM14.1525 26.4777C12.3591 26.4777 10.9005 25.0191 10.9005 23.2258C10.9005 21.8101 11.8151 20.6157 13.0801 20.17V18.5723C13.0801 17.9733 13.565 17.4883 14.1641 17.4883C14.7631 17.4883 15.248 17.9733 15.248 18.5723V20.1774C16.5011 20.6293 17.4044 21.8189 17.4044 23.2258C17.4044 23.8251 16.9195 24.3098 16.3205 24.3098C15.7212 24.3098 15.2365 23.8251 15.2365 23.2258C15.2365 22.6276 14.7504 22.1418 14.1525 22.1418C13.5543 22.1418 13.0685 22.6276 13.0685 23.2258C13.0685 23.8239 13.5543 24.3098 14.1525 24.3098C15.9456 24.3098 17.4044 25.7686 17.4044 27.5617C17.4044 28.9686 16.5011 30.1582 15.248 30.6104V31.5801C15.248 32.1791 14.7631 32.6641 14.1641 32.6641C13.565 32.6641 13.0801 32.1791 13.0801 31.5801V30.6175C11.8151 30.172 10.9005 28.9774 10.9005 27.5617C10.9005 26.9627 11.3852 26.4777 11.9845 26.4777C12.5835 26.4777 13.0685 26.9627 13.0685 27.5617C13.0685 28.1599 13.5543 28.6457 14.1525 28.6457C14.7504 28.6457 15.2365 28.1599 15.2365 27.5617C15.2365 26.9635 14.7504 26.4777 14.1525 26.4777Z" fill="url(#paint0_linear_1296_5960)"/>
        <path d="M17.2165 2.16797C16.7673 0.908966 15.5755 0 14.1641 0C12.7509 0 11.5453 0.906143 11.0973 2.16797H7.66017C7.31183 2.16797 6.98466 2.33508 6.78056 2.61794C6.57732 2.90051 6.52114 3.26466 6.63236 3.59493L8.32468 8.81641H20.0038L21.6958 3.59493C21.807 3.26466 21.7508 2.90051 21.5476 2.61794C21.3435 2.33508 21.0163 2.16797 20.668 2.16797H17.2165Z" fill="#F4F2F3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5459 17.4883H35.374C36.272 17.4883 37 18.2163 37 19.1143C37 20.0122 36.272 20.7402 35.374 20.7402H25.5459C24.6479 20.7402 23.9199 20.0122 23.9199 19.1143C23.9199 18.2163 24.6479 17.4883 25.5459 17.4883ZM35.374 22.9082H25.5459C24.6479 22.9082 23.9199 23.6362 23.9199 24.5342C23.9199 25.4321 24.6479 26.1602 25.5459 26.1602H35.374C36.272 26.1602 37 25.4321 37 24.5342C37 23.6362 36.272 22.9082 35.374 22.9082ZM25.5459 28.3281H35.374C36.272 28.3281 37 29.0561 37 29.9541C37 30.8521 36.272 31.5801 35.374 31.5801H25.5459C24.6479 31.5801 23.9199 30.8521 23.9199 29.9541C23.9199 29.0561 24.6479 28.3281 25.5459 28.3281ZM25.5459 33.748H35.374C36.272 33.748 37 34.4761 37 35.374C37 36.272 36.272 37 35.374 37H25.5459C24.6479 37 23.9199 36.272 23.9199 35.374C23.9199 34.4761 24.6479 33.748 25.5459 33.748Z" fill="url(#paint1_linear_1296_5960)"/>
        <defs>
            <linearGradient id="paint0_linear_1296_5960" x1="11.6564" y1="10.9844" x2="11.6564" y2="37" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F4F2F3"/>
                <stop offset="1" stop-color="#9D9EA2"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1296_5960" x1="30.46" y1="17.4883" x2="30.46" y2="37" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F8F6F6"/>
                <stop offset="1" stop-color="#ECECEC" stop-opacity="0.41"/>
            </linearGradient>
        </defs>
    </svg>
  )
}

export default ExpensesIcon