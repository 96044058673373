import React from 'react';
import './SkillsRating.css';


function SkillsRating() {
    return (
        <div className='skillsrating'>
            <div>
            <h4 style={{margin:'6px 12px',textAlign:'left',color:'#4B4B4B'}}>Skills Rating</h4>
            </div>
        </div>
    )
}

export default SkillsRating
