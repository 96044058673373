import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CircularService from "../../../services/circular.service";
import moment from "moment";

function CircularsList() {
  const [deviceSize, setDeviceSize] = useState("pc");

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 640) {
        setDeviceSize("sm");
      } else if (screenSize < 720) {
        setDeviceSize("md");
      } else if (screenSize < 1024) {
        setDeviceSize("xl");
      } else if (screenSize < 1280) {
        setDeviceSize("2xl");
      } else {
        setDeviceSize("pc");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { user } = useSelector((state) => state.user);
  const [circularsArray, setCircularsArray] = useState([]);
  useEffect(() => {
    CircularService.getCirculars(user?.school_code).then((res) => {
      res.forEach((circular, index) => {
        const newRow = {
          id: circular.id,
          subject: circular.title,
          date: moment(circular.date).format("DD-MM-YYYY"),
        };
        setCircularsArray((prevState) => [...prevState, newRow]);
      });
    });
  }, [user]);

  return (
    <div
      style={{
        height: "360px",
        width: `${deviceSize === "pc" ? "43%" : "100%"}`,
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        // border: "1px solid #E7E7E7",
        boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.45)",
      }}
    >
      {/* top heading box */}
      <div
        style={{
          width: "100%",
          height: "60px",
          backgroundColor: "#F9F9F9",
          borderRadius: "10px 10px 0px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: `${deviceSize === "sm" ? "18px" : "20px"}`,
            fontWeight: "600",
            paddingLeft: "20px",
            color: "#000000",
          }}
        >
          Circulars
        </div>
      </div>

      {/* bottom box */}
      <div
        style={{
          height: "300px",
          display: "flex",
          flexDirection: "column",
          paddingTop: "15px",
          borderRadius: "0px 0px 10px 10px",
          backgroundColor: "white",
        }}
      >
        {/* title */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "20px",
            padding: "0px 20px",
          }}
        >
          <p style={{ width: "200px" }}>Subject</p>
          <p style={{ width: "110px" }}>Date</p>
        </div>

        {/* List of circulars box */}
        <div
          className="custom-scrollbar"
          style={{
            height: "80%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            paddingLeft: "20px",
            paddingRight: "20px",
            background: "white",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          {circularsArray.map((circular, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontFamily: "Roboto",
                fontSize: `${deviceSize === "sm" ? "16px" : "18px"}`,
                fontWeight: "400",
                lineHeight: `${deviceSize === "sm" ? "18px" : "20px"}`,
                padding: "15px 0px",
              }}
            >
              <div style={{ width: "200px" }}>{circular.subject}</div>
              <div style={{ width: "100px" }}>{circular.date}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CircularsList;
