import React, { useState, useEffect } from "react";
import "./Login.css";
import bannerOne from "./bannerOne.svg";
import bannerTwo from "./bannerTwo.svg";
import bannerThree from "./bannerThree.svg";
import bannerFour from "./bannerFour.svg";
import bannerFive from "./bannerFive.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { login, handleTokenExpiry } from "../../slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { schooldata } from "../../slices/school";
import { toast } from "react-toastify";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
    errors: {},
  });
  const [bannerIndex, setBannerIndex] = useState(0); // added state for banner index

  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();
  const history = useHistory();

  // check if the user is already logged in
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    console.log("Current User:", user); // Add this line to check if the user is updated

    if (user) {
      const user_id = user?.id?.toString();
      if (user_id?.startsWith("17")) {

        history.push("/student/home");
      } else if (user_id?.startsWith("14")) {
        history.push("/teacher");
      } else if (user_id?.startsWith("15")) {
        history.push("/class_teacher");
      } else if (user_id?.startsWith("13")) {
        history.push("/principal");
      } else if (user_id?.startsWith("16")) {
        history.push("/accounts");
      } else if (user_id?.startsWith("12")) {
        console.log("User ID starts with 12:", user_id);
        history.push("/admin");
      } else if (user_id?.startsWith("10")) {
        history.push("/master");
      } else if (user_id?.startsWith("18")) {
        history.push("/staff");
      } else {
        history.push("/");
      }
      dispatch(handleTokenExpiry());
    }
    // Retrieve login data from local storage and set the state if it exists
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (loginData && loginData.rememberMe) {
      setFormData({ ...formData, email: loginData.email, rememberMe: true });
      // perform other actions to indicate successful login
    }
    // Start rotating banners
    const bannerInterval = setInterval(() => {
      setBannerIndex((bannerIndex) => (bannerIndex + 1) % 5);
    }, 2000);
    return () => clearInterval(bannerInterval); // Stop rotating banners when the component is unmounted
  }, [user]);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async (event) => {
    console.log("login hua ");

    event.preventDefault();

    // validation code
    const errors = {};
    if (!formData.email) {
      errors.email = "PowerEdu Id is required";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    if (Object.keys(errors).length === 0) {
      const password = formData.password;
      const userid = formData.email;
      const rememberMe = formData.rememberMe;
      // send email and password to server for authentication
      // perform any necessary actions to indicate successful login
      const response = await dispatch(login({ userid, password, rememberMe }));
      const schoolCode = response?.payload?.response?.schoolcode;
      const user_id = response?.payload?.response?.id.toString();
      if (user_id?.startsWith("17")) {
        history.push("/student/home");
      } else if (user_id?.startsWith("14")) {
        history.push("/teacher");
      } else if (user_id?.startsWith("15")) {
        history.push("/class_teacher");
      } else if (user_id?.startsWith("13")) {
        history.push("/principal");
      } else if (user_id?.startsWith("16")) {
        history.push("/accounts");
      } else if (user_id?.startsWith("12")) {
        history.push("/admin");
      } else if (user_id?.startsWith("10")) {
        history.push("/master");
      } else if (user_id?.startsWith("18")) {
        history.push("/staff");
      } else {
        history.push("/");
      }
      dispatch(handleTokenExpiry()); // start checking token expiry
      if (schoolCode) {
        dispatch(schooldata({ code: schoolCode }));
      }
    } else {
      setFormData({ ...formData, errors });
    }
  };

  const bannerSources = [
    bannerOne,
    bannerTwo,
    bannerThree,
    bannerFour,
    bannerFive,
  ];

  return (
    <div className="login-form-with-image">
      <div className="login-form-with-image__image-container">
        <img
          src={bannerSources[bannerIndex]}
          alt="Banner"
          className="login-form-with-image__image"
        />
      </div>
      <div className="login-form-with-image__form-container">
        <form className="login-form-with-image__form" onSubmit={handleSubmit}>
          <div className="login-form-with-image__form-header">
            <h2 className="login-form-with-image__form-title">Sign In</h2>
          </div>
          {/* <br /> */}
          <div className="login-form-with-image__form-field">
            <label
              htmlFor="email"
              className="login-form-with-image__form-label"
            >
              Login Id
            </label>
            <input
              placeholder="Enter Your PowerEdu Id"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {formData.errors.email && (
              <div className="error">{formData.errors.email}</div>
            )}
          </div>
          {/* <br />
          <br /> */}
          <div className="login-form-with-image__form-field">
            <label
              htmlFor="password"
              className="login-form-with-image__form-label"
            >
              Password
            </label>
            <div className="password-field">
              <input
                placeholder="Enter Your Password"
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={handlePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </button>
            </div>
            {formData.errors.password && (
              <div className="error">{formData.errors.password}</div>
            )}
            <br />
            <div style={{ textAlign: "right" }}>
              <a style={{ color: "#102371", fontSize: "16px" }} href="/forgot">
                Forgot Password?
              </a>
            </div>
          </div>
          <div
            className="login-form-with-image__keep-signed-in"
            style={{ textAlign: "left" }}
          >
            <label
              htmlFor="rememberMe"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
                style={{ marginRight: "10px", transform: "scale(1.5)" }}
              />
              Keep me signed in
            </label>
          </div>
          <br />
          {message && <div className="error">{message}</div>}
          <button className="login-form-with-image__form" type="submit">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
