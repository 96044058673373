const SidebarItems = [
    {
        name: "Home",
        route: "/student/home",
    },
    {
        name: "Subjects",
        route: "/student/subject"
    },
    {
        name: "Assignments",
        route: "/student/assignment"
    },
    {
        name: "Teachers",
        route: "/student/teacher"
    },
    {
        name: "Fees",
        route: "/student/fee"
    },
    {
        name: "Exam",
        route: "/student/exam"
    },
    {
        name: "Notice",
        route: "/student/notice"
    },
];

export default SidebarItems;