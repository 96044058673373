import React, { useEffect, useState } from "react";

function MeetingsList({ date }) {
  const timeArray = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
    "24:00",
  ];

  const meetings = [
    {
      id: 1,
      name: "Neha Bakshi",
      date: "08-02-2024",
      startTime: "10:00",
      endTime: "10:30",
    },
    {
      id: 2,
      name: "Satvir Kaur",
      date: "09-02-2024",
      startTime: "11:30",
      endTime: "12:30",
    },
    {
      id: 3,
      date: "",
      name: "Girish Pandey",
      startTime: "13:30",
      endTime: "14:30",
    },
    {
      id: 4,
      date: "09-02-2024",
      name: "Akshay Mohindsssssra",
      startTime: "15:00",
      endTime: "15:30",
    },
  ];

  const [meetingsIndexArray, setMeetingsIndexArray] = useState([]);

  function doubleDigit(number) {
    return number > 9 ? number : `0${number}`;
  }

  function formatDate(date) {
    const day = doubleDigit(date.getDate());
    const month = doubleDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    const updatedMeetingsIndexArray = [];
    meetings.forEach((meeting, index) => {
      if (meeting.date && meeting.date === formatDate(date)) {
        const startIndex = timeArray.indexOf(meeting.startTime);
        const endIndex = timeArray.indexOf(meeting.endTime);
        updatedMeetingsIndexArray.push({
          startIndex: startIndex,
          endIndex: endIndex,
          meetingName: meeting.name,
        });
      }
    });
    setMeetingsIndexArray(updatedMeetingsIndexArray);
  }, [date]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        marginTop: "30px",
        // background: "skyblue",
        marginBottom: "50px",
        position: "relative",
      }}
    >
      {timeArray.map((time, index) => (
        <div key={index} style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              flex: 1,
              height: "30px",
              //   backgroundColor: "pink",
              display: "flex",
              justifyContent: "right",
              position: "relative",
            }}
          >
            {index !== timeArray.length && (
              <div
                style={{
                  position: "absolute",
                  top: "-12px",
                }}
              >
                {time}
              </div>
            )}
          </div>
          <div
            style={{
              width: "80%",
              height: "30px",
              borderTop: "1px solid #E3E3E3",
              //   borderTop: "1px solid #000000",
              //   backgroundColor: "yellow",
            }}
          ></div>
        </div>
      ))}

      {meetingsIndexArray.map((meeting, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            height: `${(meeting.endIndex - meeting.startIndex) * 30 + 10}px`,
            width: "60%",
            top: `${meeting.startIndex * 30 - 5}px`,
            right: "10%",
            display: "grid",
            placeContent: "center",
            borderRadius: "5px",
            backgroundImage: "linear-gradient(to right, #8B64FC, #3D3AC8)",
            // backgroundColor: "blue",
            color: "white",
            fontFamily: "Rubik",
            fontSize: "100%",
            fontWeight: "500",
            textAlign:"center"
          }}
        >
          {meeting.meetingName}
        </div>
      ))}
    </div>
  );
}

export default MeetingsList;
