import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccountsHome from "../Home/AccountsHome";

import Layout from "./Layout";

function Routes() {
  return (
    <>
      <Router>
        <Route render={(props)=>(
            <Layout {...props}>
                <Switch>
                    <Route exact path="/accounts" component={AccountsHome}></Route>
                    <Redirect to="/accounts" />
                </Switch>
            </Layout>
        )}/>
      </Router>
    </>
  );
}

export default Routes;
