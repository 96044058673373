import React from 'react';
import './OverallGrade.css'

function OverallGrade() {
    return (
        <div className='overallgrade'>
            <div>
            <h4 style={{margin:'6px 12px',textAlign:'left',color:'#4B4B4B'}}>Overall Grade</h4>
            </div>
            <div style={{margin:'auto'}}>
                <h1 style={{fontSize:'70px'}}>A</h1>
            </div>
        </div>
    )
}

export default OverallGrade
