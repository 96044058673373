import React from 'react'

const TransportIcon = () => {
  return (
    <svg width="38" height="41" viewBox="0 0 38 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 12.5H4V18.5H1V12.5Z" fill="#FBFAFA"/>
        <path d="M4.53707 8.61313C4.51272 7.22496 4.82426 5.85147 5.44517 4.60967C5.79924 3.90153 6.42999 3.371 7.18832 3.1435L11 2L13.2792 1.54415C13.4262 1.51476 13.5752 1.49642 13.7249 1.48929L23.4871 1.02442C23.8266 1.00826 24.1663 1.04989 24.4918 1.14755L28.732 2.41961C28.9059 2.47176 29.092 2.46549 29.262 2.40177C31.0706 1.72351 33 3.06056 33 4.99224V32C33 33.6569 31.6569 35 30 35H7.94783C6.31149 35 4.97699 33.6887 4.94829 32.0526L4.53707 8.61313Z" fill="url(#paint0_linear_1296_5910)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2948 35.8811H7.6399C5.45059 35.8811 3.65625 34.0868 3.65625 31.8974V6.82186C3.65625 2.15546 11.5897 0 18.9702 0C26.345 0 34.2785 2.12161 34.2785 6.82186V31.8636C34.2785 34.0868 32.4841 35.8811 30.2948 35.8811ZM18.9702 1.17365C11.9508 1.17365 4.80169 3.13727 4.80169 6.82186V31.8636C4.80169 33.4322 6.07127 34.7074 7.6399 34.7074H30.2948C31.8634 34.7074 33.1387 33.4322 33.1387 31.8636V6.82186C33.1387 4.70026 30.8196 3.39683 28.8955 2.68022C26.2829 1.69841 22.7563 1.17365 18.9702 1.17365Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8182 6.63002H15.1161C14.5632 6.63002 14.0723 6.17297 14.0723 5.61436V3.91595C14.0723 3.32912 14.5293 2.87207 15.1161 2.87207H22.8182C23.4107 2.87207 23.8678 3.32912 23.8678 3.91595V5.61436C23.8678 6.17297 23.4107 6.63002 22.8182 6.63002ZM15.2121 5.48458H22.7223V4.01751H15.2121V5.48458Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7255 23.0492H4.24308C3.91581 23.0492 3.65625 22.7896 3.65625 22.4623C3.65625 22.1351 3.91581 21.8755 4.24308 21.8755H33.7255C34.0528 21.8755 34.3123 22.1351 34.3123 22.4623C34.3123 22.7896 34.0189 23.0492 33.7255 23.0492ZM33.7255 9.20794H4.24308C3.91581 9.20794 3.65625 8.94838 3.65625 8.64933C3.65625 8.35591 3.91581 8.0625 4.24308 8.0625H33.7255C34.0528 8.0625 34.3123 8.3277 34.3123 8.64933C34.3123 8.9766 34.0189 9.20794 33.7255 9.20794Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9364 23.0498C18.6092 23.0498 18.3496 22.7903 18.3496 22.463V8.62177C18.3496 8.2945 18.6092 8.0293 18.9364 8.0293C19.2637 8.0293 19.4894 8.32835 19.4894 8.62177V22.463C19.4894 22.7903 19.2637 23.0498 18.9364 23.0498Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.3445 23.0498C31.0172 23.0498 30.752 22.7903 30.752 22.463V8.62177C30.752 8.2945 31.0172 8.0293 31.3445 8.0293C31.6661 8.0293 31.8975 8.32835 31.8975 8.62177V22.463C31.8975 22.7903 31.6661 23.0498 31.3445 23.0498ZM6.63007 23.0498C6.3028 23.0498 6.0376 22.7903 6.0376 22.463V8.62177C6.0376 8.2945 6.3028 8.0293 6.63007 8.0293C6.95169 8.0293 7.21689 8.2945 7.21689 8.62177V22.463C7.18304 22.7903 6.92348 23.0498 6.63007 23.0498Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1666 31.4751H7.89957C7.21682 31.4751 6.6582 30.9221 6.6582 30.2338V27.7172C6.6582 27.0344 7.21682 26.4814 7.89957 26.4814H11.1666C11.8494 26.4814 12.408 27.0344 12.408 27.7172V30.1999C12.408 30.9221 11.8494 31.4751 11.1666 31.4751ZM7.89957 27.6551C7.8375 27.6551 7.80364 27.7172 7.80364 27.751V30.2338C7.80364 30.3015 7.86571 30.3297 7.89957 30.3297H11.1666C11.2287 30.3297 11.2625 30.2676 11.2625 30.2338V27.7172C11.2625 27.689 11.2287 27.6212 11.1666 27.6212H7.89957V27.6551Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0694 31.4751H26.8024C26.1196 31.4751 25.561 30.9221 25.561 30.2338V27.7172C25.561 27.0344 26.1196 26.4814 26.8024 26.4814H30.0694C30.7522 26.4814 31.3108 27.0344 31.3108 27.7172V30.1999C31.3108 30.9221 30.7522 31.4751 30.0694 31.4751ZM26.8024 27.6551C26.7403 27.6551 26.7065 27.7172 26.7065 27.751V30.2338C26.7065 30.3015 26.7742 30.3297 26.8024 30.3297H30.0694C30.1372 30.3297 30.1654 30.2676 30.1654 30.2338V27.7172C30.1654 27.689 30.1372 27.6212 30.0694 27.6212H26.8024V27.6551Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9639 26.9666H14.0048C13.6775 26.9666 13.418 26.7071 13.418 26.3798C13.418 26.0525 13.6775 25.793 14.0048 25.793H23.9639C24.2912 25.793 24.5508 26.0525 24.5508 26.3798C24.5508 26.7071 24.2912 26.9666 23.9639 26.9666Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1357 29.449H15.8329C15.5057 29.449 15.2461 29.1895 15.2461 28.8622C15.2461 28.5349 15.5057 28.2754 15.8329 28.2754H22.1357C22.4629 28.2754 22.7225 28.5349 22.7225 28.8622C22.7225 29.1895 22.4291 29.449 22.1357 29.449Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2734 31.9324H17.6947C17.3675 31.9324 17.1079 31.6729 17.1079 31.3456C17.1079 31.0183 17.3675 30.7588 17.6947 30.7588H20.2734C20.6007 30.7588 20.8602 31.0183 20.8602 31.3456C20.8602 31.6729 20.6007 31.9324 20.2734 31.9324Z" fill="#3C3D48"/>
        <path d="M34 12.5H37V18.5H34V12.5Z" fill="#FBFAFA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.5537 19.3929H33.7255C33.3982 19.3929 33.1387 19.1333 33.1387 18.806C33.1387 18.4788 33.3982 18.2192 33.7255 18.2192H35.5537C36.2421 18.2192 36.8289 17.6324 36.8289 16.944V14.0719C36.8289 13.3835 36.2703 12.7967 35.5537 12.7967H33.7255C33.3982 12.7967 33.1387 12.5371 33.1387 12.2099C33.1387 11.8826 33.3982 11.623 33.7255 11.623H35.5537C36.891 11.623 37.9687 12.7008 37.9687 14.0381V16.9101C37.9349 18.3151 36.8571 19.3929 35.5537 19.3929Z" fill="#3C3D48"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.24321 19.3935H2.41502C1.07773 19.3935 0 18.3158 0 16.9785V14.1064C0 12.7635 1.07773 11.6914 2.41502 11.6914H4.24321C4.57048 11.6914 4.83004 11.951 4.83004 12.2782C4.83004 12.6055 4.57048 12.8651 4.24321 12.8651H2.41502C1.73227 12.8651 1.1398 13.4519 1.1398 14.1346V17.0123C1.1398 17.729 1.73227 18.2819 2.41502 18.2819H4.24321C4.57048 18.2819 4.83004 18.5471 4.83004 18.8688C4.83004 19.196 4.57048 19.3935 4.24321 19.3935Z" fill="#3C3D48"/>
        <path d="M9 36H13.5L13 39.5H9.5L9 36Z" fill="#ECECEC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6236 40.1584H10.8731C9.37222 40.1584 8.13086 38.917 8.13086 37.4161V35.2607C8.13086 34.9334 8.39042 34.6738 8.71769 34.6738C9.04496 34.6738 9.30451 34.9334 9.30451 35.2607V37.4161C9.30451 38.2964 10.0211 39.013 10.907 39.013H11.6575C12.5038 39.013 13.2205 38.2964 13.2205 37.4161V35.2607C13.2205 34.9334 13.4857 34.6738 13.8073 34.6738C14.1346 34.6738 14.3998 34.9334 14.3998 35.2607V37.4161C14.332 38.9509 13.1245 40.1584 11.6236 40.1584Z" fill="#3C3D48"/>
        <path d="M24.5 35.5H29L28.5 39H25L24.5 35.5Z" fill="#ECECEC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0956 40.1584H26.3452C24.8442 40.1584 23.6367 38.917 23.6367 37.4161V35.2607C23.6367 34.9334 23.8963 34.6738 24.2235 34.6738C24.5508 34.6738 24.8104 34.9334 24.8104 35.2607V37.4161C24.8104 38.2964 25.5326 39.013 26.379 39.013H27.1295C28.0097 39.013 28.732 38.2964 28.732 37.4161V35.2607C28.732 34.9334 28.9915 34.6738 29.3188 34.6738C29.6461 34.6738 29.9056 34.9334 29.9056 35.2607V37.4161C29.8097 38.9509 28.6022 40.1584 27.0956 40.1584Z" fill="#3C3D48"/>
        <defs>
            <linearGradient id="paint0_linear_1296_5910" x1="18.75" y1="1" x2="18.75" y2="35" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FDFBFB"/>
                <stop offset="1" stop-color="#F5F5F5" stop-opacity="0.41"/>
            </linearGradient>
        </defs>
    </svg>

  )
}

export default TransportIcon