import React, { useState } from "react";
import "./SubDropdown.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Accordion } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BasicTable from "./DropdownMenu";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "../StudentSubject.module.css";
import { Typography } from "@mui/material";

function SubDropdown({ subject, index, activeItem }) {
  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#646464",
            fontSize: "24px !important",
            lineHeight: "28px !important",
          }}
        >
          {subject?.subject_name}
        </Typography>
        <KeyboardArrowDownIcon
          style={{
            fontSize: "35px",
            fontWeight: "lighter",
            transform: activeItem === index ? "rotate(180deg)" : "none",
            transition: "transform 0.2s ease-in-out",
          }}
        />
      </Accordion.Header>
      {/*check if chapter exist*/}
      <Accordion.Body
        style={{
          background: "#FFFFFF",
          border: "1px solid #A5A5A5",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
        }}
      >
        {/* <div className="dropdownmenu" > */}
        {subject?.chapters?.length > 0 ? (
          <BasicTable chapters={subject?.chapters} />
        ) : (
          <h5 className={styles.div2h2}>"No chapters found.!"</h5>
        )}
        {/* </div> */}
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default SubDropdown;
