import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import StudentAssignment from "../Assignment/StudentAssignment";
import StudentExam from "../Exam/StudentExam";
import StudentFee from "../Fee/StudentFee";
import StudentHome from "../Home/StudentHome"
import StudentSubject from "../Subject/StudentSubject";
import StudentTeacher from "../Teacher/StudentTeacher";
import StudentNotice from "../Notice/StudentNotice"
import Layout from "./Layout";
import Profile from "../StudentProfile/Profile";

function Routes() {

  return (
    <>
      <Router>
        <Route render={(props) => (
          <Layout {...props}>
            <Switch>
              <Route exact path="/student/home" component={StudentHome} />
              <Route exact path="/student/subject" component={StudentSubject} />
              <Route exact path="/student/assignment" component={StudentAssignment} />
              <Route exact path="/student/teacher" component={StudentTeacher} />
              <Route exact path="/student/fee" component={StudentFee} />
              <Route exact path="/student/exam" component={StudentExam} />
              <Route exact path="/student/notice" component={StudentNotice} />
              <Route exact path="/student/home/profile" component={Profile} />
              <Redirect to="/student/home" />
            </Switch>
          </Layout>
        )} />
      </Router>
    </>
  );
}

export default Routes;
