import axios from "axios";

const API_URL = "https://api.poweredu.in/api/subjects/";

const registerSubject = async (
  schoolCode,
  className,
  subjectName,
  createdBy
) => {
  try {
    const response = await axios.post(`${API_URL}register-subject`, {
      schoolCode,
      className,
      subjectName,
      createdBy,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllSubjectsDetails = async (class_code, school_code, subject_code) => {
  try {
    const response = await axios.post(`${API_URL}class-subjects`, {
      class_code,
      school_code,
      subject_code,
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllSubjectsBySchool = async (schoolcode) => {
  try {
    const response = await axios.post(`${API_URL}school-subjects`, {
      schoolcode,
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAssignedSubjects = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}${userId}/subject-details`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//args(clsid,shclcode,subjectid)
const updateStatus = async (chapterId, status, chapter_number) => {
  try {
    // console.log("here", chapterId, status)
    const response = await axios.put(
      `https://api.poweredu.in/api/chapters/status`,
      { chapterId, status, chapter_number }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getDropdownSubjectsByClass = async (
  schoolcode,
  class_code,
  timetable
) => {
  try {
    const response = await axios.post(`${API_URL}class-subjects-dropdown`, {
      schoolcode,
      class_code,
      timetable,
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllDropdownSubjectsByClass = async (class_code) => {
  try {
    const response = await axios.post(`${API_URL}class-all-subjects-dropdown`, {
      class_code,
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateSubjectsOfClasses = async (body) => {
  try {
    const response = await axios.post(
      `${API_URL}update-subjects-of-classes`,
      body
    );
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getSubjectsOfClasses = async (body) => {
  try {
    const response = await axios.post(
      `${API_URL}get-subjects-of-classes`,
      body
    );
    // console.log(response.data)
    console.log(response.data,"reponsedatad")

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getSearchSubjectsOfClasses = async (body) => {
  try {
    const response = await axios.post(
      `${API_URL}get-search-subject-classes`,
      body
    );
    // console.log(response.data)
    console.log(response.data,"reponsedatad")

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


const deleteSubjectsOfClasses = async (body) => {
  try {
    const response = await axios.post(
      `${API_URL}delete-subjects-of-classes`,
      body
    );
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const SubjectService = {
  registerSubject,
  getAllSubjectsDetails,
  getAssignedSubjects,
  updateStatus,
  getDropdownSubjectsByClass,
  getAllSubjectsBySchool,
  getAllDropdownSubjectsByClass,
  updateSubjectsOfClasses,
  getSubjectsOfClasses,
  deleteSubjectsOfClasses,
  getSearchSubjectsOfClasses
};

export default SubjectService;
