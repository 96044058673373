import React from "react";

function UploadIconSvg() {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        // cursor:"pointer"
      }}
    >
      <svg
        width="86"
        height="55"
        viewBox="0 0 86 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1322_16155" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.3958 33.0111C77.0807 24.8515 70.3664 18.3333 62.1296 18.3333C60.3319 18.3333 58.6067 18.6438 57.0048 19.2141C56.4054 8.50182 47.5289 0 36.6667 0C25.4164 0 16.2963 9.12013 16.2963 20.3704C16.2963 21.058 16.3304 21.7376 16.3969 22.4077C16.3634 22.4075 16.3299 22.4074 16.2963 22.4074C7.2961 22.4074 0 29.7035 0 38.7037C0 42.0732 1.02263 45.2039 2.77445 47.8022C4.38647 52.0114 8.46458 55 13.2407 55H16.2963H74.3518C80.5395 55 85.5556 49.9839 85.5556 43.7963C85.5556 38.6637 82.1043 34.3373 77.3958 33.0111Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.3958 33.0111C77.0807 24.8515 70.3664 18.3333 62.1296 18.3333C60.3319 18.3333 58.6067 18.6438 57.0048 19.2141C56.4054 8.50182 47.5289 0 36.6667 0C25.4164 0 16.2963 9.12013 16.2963 20.3704C16.2963 21.058 16.3304 21.7376 16.3969 22.4077C16.3634 22.4075 16.3299 22.4074 16.2963 22.4074C7.2961 22.4074 0 29.7035 0 38.7037C0 42.0732 1.02263 45.2039 2.77445 47.8022C4.38647 52.0114 8.46458 55 13.2407 55H16.2963H74.3518C80.5395 55 85.5556 49.9839 85.5556 43.7963C85.5556 38.6637 82.1043 34.3373 77.3958 33.0111Z"
          fill="#1EA6C6"
        />
        <path
          d="M77.3958 33.0111L74.3981 33.1268L74.4823 35.3071L76.5825 35.8987L77.3958 33.0111ZM57.0048 19.2141L54.0095 19.3817L54.2335 23.3851L58.0109 22.0404L57.0048 19.2141ZM16.3969 22.4077L16.3788 25.4077L19.7116 25.4278L19.3822 22.1113L16.3969 22.4077ZM2.77445 47.8022L5.57602 46.7293L5.45347 46.4093L5.26192 46.1252L2.77445 47.8022ZM80.3936 32.8953C80.0165 23.1314 71.9845 15.3333 62.1296 15.3333V21.3333C68.7483 21.3333 74.1449 26.5716 74.3981 33.1268L80.3936 32.8953ZM62.1296 15.3333C59.9843 15.3333 57.9191 15.7042 55.9987 16.3879L58.0109 22.0404C59.2944 21.5834 60.6796 21.3333 62.1296 21.3333V15.3333ZM60.0001 19.0465C59.3122 6.75394 49.1296 -3 36.6667 -3V3C45.9282 3 53.4985 10.2497 54.0095 19.3817L60.0001 19.0465ZM36.6667 -3C23.7596 -3 13.2963 7.46327 13.2963 20.3704H19.2963C19.2963 10.777 27.0733 3 36.6667 3V-3ZM13.2963 20.3704C13.2963 21.1571 13.3353 21.9357 13.4116 22.7042L19.3822 22.1113C19.3254 21.5395 19.2963 20.9588 19.2963 20.3704H13.2963ZM16.415 19.4078C16.3756 19.4075 16.336 19.4074 16.2963 19.4074V25.4074C16.3237 25.4074 16.3512 25.4075 16.3788 25.4077L16.415 19.4078ZM16.2963 19.4074C5.63925 19.4074 -3 28.0467 -3 38.7037H3C3 31.3604 8.95296 25.4074 16.2963 25.4074V19.4074ZM-3 38.7037C-3 42.6893 -1.78841 46.401 0.286984 49.4793L5.26192 46.1252C3.83366 44.0067 3 41.4571 3 38.7037H-3ZM13.2407 52C9.74821 52 6.75828 49.8163 5.57602 46.7293L-0.0271191 48.8752C2.01465 54.2065 7.18095 58 13.2407 58V52ZM16.2963 52H13.2407V58H16.2963V52ZM74.3518 52H16.2963V58H74.3518V52ZM82.5556 43.7963C82.5556 48.3271 78.8826 52 74.3518 52V58C82.1963 58 88.5556 51.6408 88.5556 43.7963H82.5556ZM76.5825 35.8987C80.0317 36.8702 82.5556 40.0424 82.5556 43.7963H88.5556C88.5556 37.2851 84.1769 31.8043 78.2092 30.1234L76.5825 35.8987Z"
          fill="#1EA6C6"
          mask="url(#path-1-inside-1_1322_16155)"
        />
      </svg>
      <div style={{ position: "absolute", bottom: "0px" }}>
        <svg
          width="12"
          height="23"
          viewBox="0 0 12 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 2V21"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 6L6 2L2 6"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default UploadIconSvg;
