import React from 'react'

function LogoutIcon() {
  return (
    <svg width="35" height="35" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.394 34.159C37.3925 37.2657 34.4589 39.6591 31.0135 40.996C27.5682 42.3329 23.788 42.5448 20.2149 41.6013C16.6417 40.6578 13.459 38.6073 11.1228 35.7437C8.78664 32.8801 7.41699 29.3505 7.21016 25.6606C7.00333 21.9708 7.96994 18.3102 9.97145 15.2035C11.973 12.0968 14.9066 9.70342 18.3519 8.36649C21.7973 7.02956 25.5774 6.81767 29.1506 7.76119C32.7238 8.70471 35.9065 10.7552 38.2427 13.6188L35.9999 15.4484C34.0501 13.0585 31.3938 11.3471 28.4116 10.5597C25.4295 9.77221 22.2745 9.94906 19.399 11.0649C16.5235 12.1807 14.0751 14.1782 12.4046 16.771C10.7342 19.3639 9.92741 22.4191 10.1 25.4987C10.2727 28.5782 11.4158 31.5241 13.3655 33.914C15.3153 36.304 17.9717 38.0153 20.9538 38.8028C23.936 39.5903 27.0909 39.4134 29.9664 38.2976C32.8419 37.1818 35.2904 35.1843 36.9608 32.5914L39.394 34.159Z" fill="white"/>
    <path d="M21.2929 24.2929C20.9024 24.6834 20.9024 25.3166 21.2929 25.7071L27.6569 32.0711C28.0474 32.4616 28.6805 32.4616 29.0711 32.0711C29.4616 31.6805 29.4616 31.0474 29.0711 30.6569L23.4142 25L29.0711 19.3431C29.4616 18.9526 29.4616 18.3195 29.0711 17.9289C28.6805 17.5384 28.0474 17.5384 27.6569 17.9289L21.2929 24.2929ZM50 24L22 24V26L50 26V24Z" fill="white"/>
    </svg>
  )
}

export default LogoutIcon