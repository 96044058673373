import axios from "axios";

const API_URL = "https://api.poweredu.in/api/assignment/";

const registerassignmentstatus = (schoolcode, classname, subjectname, studentname, assigndate, submitdate, status) => {
  return axios.post(API_URL + "registerassignmentstatus", {
    schoolcode, classname, subjectname, studentname, assigndate, submitdate, status
  });
};

const AssignmentStatusService = {
    registerassignmentstatus
};

export default AssignmentStatusService;
