const SidebarItems = [
  {
    name: "Home",
    route: "/admin/home",
    child: [],
  },
  {
    name: "Profiles",
    child: [
      {
        name: "Students",
        route: "/admin/students-profile",
      },
      {
        name: "Teachers",
        route: "/admin/teachers-profile",
      },
      {
        name: "Principal",
        route: "/admin/principal-profile",
      },
      {
        name: "Accountant",
        route: "/admin/accountant-profile",
      },
      {
        name: "Staff",
        route: "/admin/staff-profile",
      },
    ],
  },
  {
    name: "Calendar",
    route: "/admin/calendar",
    child: [],
  },
  {
    name: "Mail",
    route: "/admin/mail",
    child: [],
  },
  {
    name: "Subjects",
    route: "/admin/subjects",
    child: [],
  },
  {
    name: "Transport",
    route: "/admin/transport",
    child: [],
  },
  {
    name: "Expenses",
    child: [
      {
        name: "Academic Fees",
        route: "/admin/academic-fees",
      },
      {
        name: "Transport Fees",
        route: "/admin/transport-fees",
      },
      {
        name: "Extracurricular",
        route: "/admin/extracurricular",
      },
    ],
  },
  {
    name: "School",
    route: "/admin/school",
    child: [],
  },
  {
    name: "Notice",
    route: "/admin/notice",
    child: [],
  },
  {
    name: "Notice",
    route: "/teacher/notice",
    child: [],
  },
];

export default SidebarItems;
