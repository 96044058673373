import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import BackupIcon from "@mui/icons-material/Backup";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CreateNewUserModal({ onClick }) {
  const [deviceSize, setDeviceSize] = useState("pc");
  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 640) {
        setDeviceSize("sm");
      } else if (screenSize < 768) {
        setDeviceSize("md");
      } else if (screenSize < 1024) {
        setDeviceSize("xl");
      } else if (screenSize < 1280) {
        setDeviceSize("2xl");
      } else {
        setDeviceSize("pc");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "rgba(83, 83, 83,0.35)",
        zIndex: "10",
        display: "grid",
        placeContent: "center",
      }}
    >
      <div
        style={{
          width: `${
            deviceSize === "pc"
              ? "686px"
              : deviceSize === "sm"
              ? "75vw"
              : "60vw"
          }`,
          height: `${deviceSize === "pc" ? "428px" : "50vh"}`,
          border: "2px solid #FFFFFF",
          backgroundColor: "red",
        }}
      >
        {/* heading box */}
        <div
          style={{
            width: "100%",
            height: "54px",
            backgroundColor: "#FBFBFB",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 3%",
          }}
        >
          <h1
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "600",
              color: "#494949",
            }}
          >
            Create New User
          </h1>
          <div
            style={{
              height: "32px",
              width: "32px",
              borderRadius: "50px",
              backgroundColor: "red",
              color: "white",
              display: "grid",
              placeContent: "center",
              cursor: "pointer",
            }}
            onClick={onClick}
          >
            <CloseIcon />
          </div>
        </div>

        {/* buttons container */}
        <div
          style={{
            height: "calc(100% - 54px)",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10%",
          }}
        >
          {/* button 1 */}
          <Link to="./addUser" style={{ textDecoration: "none" }}>
            <div
              style={{
                width: `${deviceSize === "pc" ? "223px" : "40%"}`,
                height: `${deviceSize === "pc" ? "172px" : "40%"}`,
                backgroundColor: "#F6FCFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  height: `${deviceSize === "pc" ? "60px" : "40px"}`,
                  width: `${deviceSize === "pc" ? "60px" : "40px"}`,
                  borderRadius: "50px",
                  backgroundColor: "#2196F3",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloseIcon
                  style={{
                    transform: "rotate(45deg)",
                    height: "80%",
                    width: "80%",
                    color: "white",
                  }}
                />
              </div>
              <p
                style={{
                  fontFamily: "Outfit",
                  fontSize: "16px",
                  fontWeight: "400",
                  textAlign: "center",
                  letterSpacing: "4%",
                  color: "#686868",
                }}
              >
                Create New
              </p>
            </div>
          </Link>

          {/* button 2 */}
          <div
            style={{
              width: `${deviceSize === "pc" ? "223px" : "40%"}`,
              height: `${deviceSize === "pc" ? "172px" : "40%"}`,
              backgroundColor: "#F3FEFC",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10%",
              cursor: "pointer",
            }}
          >
            <BackupIcon
              style={{
                width: "60px",
                height: "45",
                color: "#39B29D",
              }}
            />
            <p
              style={{
                fontFamily: "Outfit",
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
                letterSpacing: "4%",
                color: "#686868",
              }}
            >
              Upload From Rekor Id
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewUserModal;
