
import React from "react";

function CalenderIcon() {
    return (
        <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6055 6H4.20068C2.43299 6 1 7.43268 1 9.19997V31.5998C1 33.3671 2.43299 34.7997 4.20068 34.7997H26.6055C28.3731 34.7997 29.8061 33.3671 29.8061 31.5998V9.19997C29.8061 7.43268 28.3731 6 26.6055 6Z" fill="url(#paint0_linear_1296_5894)" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.8044 1V7.39994" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.0014 1V7.39994" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 13H29.8061" stroke="#DBDBDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.0645 22.0547V23.3555C14.0645 24.0547 14.002 24.6445 13.877 25.125C13.752 25.6055 13.5723 25.9922 13.3379 26.2852C13.1035 26.5781 12.8203 26.791 12.4883 26.9238C12.1602 27.0527 11.7891 27.1172 11.375 27.1172C11.0469 27.1172 10.7441 27.0762 10.4668 26.9941C10.1895 26.9121 9.93945 26.7812 9.7168 26.6016C9.49805 26.418 9.31055 26.1797 9.1543 25.8867C8.99805 25.5938 8.87891 25.2383 8.79688 24.8203C8.71484 24.4023 8.67383 23.9141 8.67383 23.3555V22.0547C8.67383 21.3555 8.73633 20.7695 8.86133 20.2969C8.99023 19.8242 9.17188 19.4453 9.40625 19.1602C9.64062 18.8711 9.92188 18.6641 10.25 18.5391C10.582 18.4141 10.9531 18.3516 11.3633 18.3516C11.6953 18.3516 12 18.3926 12.2773 18.4746C12.5586 18.5527 12.8086 18.6797 13.0273 18.8555C13.2461 19.0273 13.4316 19.2578 13.584 19.5469C13.7402 19.832 13.8594 20.1816 13.9414 20.5957C14.0234 21.0098 14.0645 21.4961 14.0645 22.0547ZM12.9746 23.5312V21.873C12.9746 21.4902 12.9512 21.1543 12.9043 20.8652C12.8613 20.5723 12.7969 20.3223 12.7109 20.1152C12.625 19.9082 12.5156 19.7402 12.3828 19.6113C12.2539 19.4824 12.1035 19.3887 11.9316 19.3301C11.7637 19.2676 11.5742 19.2363 11.3633 19.2363C11.1055 19.2363 10.877 19.2852 10.6777 19.3828C10.4785 19.4766 10.3105 19.627 10.1738 19.834C10.041 20.041 9.93945 20.3125 9.86914 20.6484C9.79883 20.9844 9.76367 21.3926 9.76367 21.873V23.5312C9.76367 23.9141 9.78516 24.252 9.82812 24.5449C9.875 24.8379 9.94336 25.0918 10.0332 25.3066C10.123 25.5176 10.2324 25.6914 10.3613 25.8281C10.4902 25.9648 10.6387 26.0664 10.8066 26.1328C10.9785 26.1953 11.168 26.2266 11.375 26.2266C11.6406 26.2266 11.873 26.1758 12.0723 26.0742C12.2715 25.9727 12.4375 25.8145 12.5703 25.5996C12.707 25.3809 12.8086 25.1016 12.875 24.7617C12.9414 24.418 12.9746 24.0078 12.9746 23.5312ZM20.9727 18.4688V19.0781L17.4395 27H16.2969L19.8242 19.3594H15.207V18.4688H20.9727Z" fill="#3B3C47"/>
            <defs>
            <linearGradient id="paint0_linear_1296_5894" x1="15.4031" y1="6" x2="15.4031" y2="34.7997" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FDFBFB"/>
            <stop offset="0.381053" stop-color="#FBFAFA" stop-opacity="0.880657"/>
            <stop offset="1" stop-color="#F5F5F5" stop-opacity="0.45"/>
            </linearGradient>
            </defs>
        </svg>
    )
}

export default CalenderIcon;