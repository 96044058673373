import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import BackupIcon from "@mui/icons-material/Backup";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddEventModal = ({ onClick }) => {
  const [deviceSize, setDeviceSize] = useState("pc");
  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 640) {
        setDeviceSize("sm");
      } else if (screenSize < 768) {
        setDeviceSize("md");
      } else if (screenSize < 1024) {
        setDeviceSize("xl");
      } else if (screenSize < 1280) {
        setDeviceSize("2xl");
      } else {
        setDeviceSize("pc");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    // backdrop
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "rgba(83, 83, 83,0.35)",
        zIndex: "10",
        // display: "grid",
        // placeContent: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* modal box */}
      <div
        style={{
          width: `${
            deviceSize === "pc"
              ? "686px"
              : deviceSize === "sm"
              ? "75vw"
              : "60vw"
          }`,
          //   height: `${deviceSize === "pc" ? "611px" : "30vh"}`,
          border: "2px solid #FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* heading box */}
        <div
          style={{
            width: "100%",
            height: "54px",
            backgroundColor: "#FBFBFB",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 3%",
          }}
        >
          <h1
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "600",
              color: "#494949",
            }}
          >
            Add Event
          </h1>
          <div
            style={{
              height: "32px",
              width: "32px",
              borderRadius: "50px",
              backgroundColor: "red",
              color: "white",
              display: "grid",
              placeContent: "center",
              cursor: "pointer",
            }}
            onClick={onClick}
          >
            <CloseIcon />
          </div>
        </div>

        {/* inputs container */}
        <div
          style={{
            // height: "calc(100% - 54px)",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: `${
              deviceSize === "sm"
                ? "20px 20px 100px 20px"
                : "40px 40px 100px 40px"
            }`,
            gap: "20px",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column"}}
          >
            <h1
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#494949",
              }}
            >
              Event Name
              <span
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "italic",
                  fontWeight: "300",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#B9B9B9",
                }}
              >
                (max 20 words)
              </span>
            </h1>
            <textarea
              style={{
                height: `${deviceSize === "sm" ? "40px" : "63px"}`,
                color: "#494949",
                border: "1px solid rgba(0,0,0,0.2)",
              }}
            />
          </div>

          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <h1
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#494949",
              }}
            >
              Description
            </h1>
            <textarea
              style={{
                height: `${deviceSize === "sm" ? "60px" : "100px"}`,
                color: "#494949",
                border: "1px solid rgba(0,0,0,0.2)",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                flex: "1",
                width: "57px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#494949",
                }}
              >
                Class
              </div>
              <input
                style={{
                  height: "40px",
                  color: "#494949",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
              />
            </div>

            <div
              style={{
                minWidth: "102px",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                minWidth: "140px",
              }}
            >
              <div
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#494949",
                }}
              >
                Start Date
              </div>
              <input
                type="date"
                style={{
                  width: "100%",
                  height: "40px",
                  color: "#494949",
                  border: "1px solid rgba(0,0,0,0.2)",
                  padding: "0px 10px",
                  userSelect: "none",
                }}
              />
            </div>

            <div
              style={{
                minWidth: "102px",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                minWidth: "140px",
              }}
            >
              <div
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#494949",
                }}
              >
                End Date
              </div>
              <input
                type="date"
                style={{
                  width: "100%",
                  height: "40px",
                  color: "#494949",
                  border: "1px solid rgba(0,0,0,0.2)",
                  padding: "0px 10px",
                  userSelect: "none",
                }}
              />
            </div>
          </div>
        </div>

        <button
          style={{
            position: "absolute",
            bottom: "40px",
            maxWidth: "453px",
            width: "calc(100% - 80px)",
            height: "46px",
            backgroundColor: "#3CB532",
            color: "white",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "500",
            border: "none",
          }}
        >
          Add Event
        </button>
      </div>
    </div>
  );
};

export default AddEventModal;
