import React, { useEffect, useState } from "react";
import HandWave from "../../../icons/HandWave";
import Information from "./Information";
import Button from "./Button";
import MeetingsBox from "./MeetingsBox";
import { CSSTransition } from "react-transition-group";
import "animate.css";
import "./Home.css";
import CreateNewUserModal from "./Modals/CreateNewUserModal";
import AddEventModal from "./Modals/AddEventModal";

function Home() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [deviceSize, setDeviceSize] = useState("pc");
  const yearArray = [];
  for (let year = 1951; year <= 2050; year++) {
    yearArray.push(year.toString());
  }

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 640) {
        setDeviceSize("sm");
      } else if (screenSize < 768) {
        setDeviceSize("md");
      } else if (screenSize < 1024) {
        setDeviceSize("xl");
      } else if (screenSize < 1280) {
        setDeviceSize("2xl");
      } else {
        setDeviceSize("pc");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showHandAnimation, setShowHandAnimation] = useState(false);

  useEffect(() => {
    setShowHandAnimation(true);
    const timer = setTimeout(() => {
      setShowHandAnimation(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const [isCreateNewUserModalOpen, setCreateNewUserModalOpen] = useState(false);
  const [isAddEventModalOpen, setAddEventModalOpen] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: `${
          deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl"
            ? "column"
            : "row"
        }`,
        height: "calc(100vh - 100px)",
        width: "100%",
        overflowY: `${
          deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl"
            ? "scroll"
            : "hidden"
        }`,
      }}
    >
      <div
        style={{
          boxShadow:
            "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
          // border: "2px solid red",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          height: "screen",
          paddingTop: "20px",
          height: `${
            deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl"
              ? ""
              : "100%"
          }`,
          width: `${
            deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl"
              ? ""
              : "24%"
          }`,
          alignItems: "start",
          paddingLeft: "10px",
          paddingBottom: `${
            deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl"
              ? "20px"
              : ""
          }`,
          paddingRight: `${
            deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl"
              ? "20px"
              : ""
          }`,
          gap: "10px",
        }}
      >
        <h1
          style={{
            fontFamily: "Lato",
            fontWeight: "700",
            fontSize: "25px",
            lineHeight: "30px",
          }}
        >
          Hello,
          <span
            style={{
              fontFamily: "Lato",
              fontWeight: "400",
              fontSize: "25px",
              lineHeight: "29.3px",
              marginLeft: "10px",
            }}
          >
            {user.firstname}
          </span>
          <span>
            <CSSTransition
              in={showHandAnimation}
              timeout={5000}
              classNames={{
                enter: "animate__animated animate__wobble",
                enterActive: "animate__animated animate__wobble",
              }}
              // unmountOnExit
            >
              <HandWave />
            </CSSTransition>
          </span>
        </h1>

        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: `${
              deviceSize === "md" || deviceSize === "xl" ? "row" : "column"
            }`,
            alignItems: `${
              deviceSize === "pc" || deviceSize === "2xl" ? "start" : "center"
            }`,
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: `${
                deviceSize === "sm" ||
                deviceSize === "md" ||
                deviceSize === "xl"
                  ? "row"
                  : "column"
              }`,
              gap: "5px",
              width: `${deviceSize === "sm" ? "100%" : "90%"}`,
            }}
          >
            <Button
              text="Create New User"
              onClick={() => setCreateNewUserModalOpen(true)}
            />
            <Button
              text="Upload New Event"
              onClick={() => setAddEventModalOpen(true)}
            />
            <a
              href="https://calendar.google.com/calendar/u/0/r/eventedit?vcon=meet&dates=now&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="prncplbtn">Schedule Meeting</button>
            </a>
            {/* <Button
              text="Schedule Meet"
              onClick={() => setCreateNewUserModalOpen(true)}
            /> */}
          </div>

          <div
            style={{
              width: `${
                deviceSize === "md" || deviceSize === "xl"
                  ? "40%"
                  : deviceSize === "sm"
                  ? "60%"
                  : "90%"
              }`,
              height: `${
                deviceSize === "pc" || deviceSize === "2xl" ? "100%" : "60px"
              }`,
            }}
          >
            <MeetingsBox />
          </div>
        </div>
      </div>

      <div
        style={{
          minHeight: "screen",
          backgroundColor: "#F8F9FF",
          // backgroundColor: "rgb(187, 172, 172)",
          flex: "1",
          padding: "15px",
          overflowX: `${
            deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl"
              ? ""
              : "hidden"
          }`,
        }}
      >
        <Information />
      </div>
      {isCreateNewUserModalOpen && (
        <CreateNewUserModal onClick={() => setCreateNewUserModalOpen(false)} />
      )}

      {isAddEventModalOpen && (
        <AddEventModal onClick={() => setAddEventModalOpen(false)} />
      )}
    </div>
  );
}

export default Home;
