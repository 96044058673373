import React from "react";
import "./AcademicCalendar.css";
import { MyMonthlyCalendar } from "./Calendar";
import MonthSelect from "./MonthDropdown";
import YearSelect from "./YearDropdown";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JS for Popovers
// import './CustomFullCalendar.css'; // Import your custom CSS
import timeGridPlugin from "@fullcalendar/timegrid";
import { width } from "@mui/system";
import * as Bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CalendarServices from "../../../services/calendar.service";
import { useSelector } from "react-redux";

export default function AcademicCalendar() {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState();

  const { user: currentUser } =
    useSelector((state) => state.user) ??
    JSON.parse(localStorage.getItem("user"));

  const getEvents = () => {
    const body = {
      schoolcode: currentUser.schoolcode,
      classid: currentUser.classname,
    };
    console.log(body);
    CalendarServices.getEvents(body)
      .then((res) => {
        const transformedEvents = res.data.map((event) => ({
          title: event.event_name,
          start: event.start_date, // Adjust this according to your data structure
          end: event.end_date, // Adjust this according to your data structure
          extendedProps: {
            description: event.event_desc,
            isHoliday: event.isHoliday,
          },
        }));
        setEvents(transformedEvents);
      })
      .catch((err) => console.error("error is ", err));
  };

  useEffect(() => {
    getEvents();
  }, []);

  const calendarRef = useRef(null); // Create a reference to the FullCalendar component

  const handleDateClick = (info) => {
    // Update the view to the selected date when a date tile is clicked
    setDate(info.date);
    calendarRef.current.getApi().gotoDate(info.date);
  };

  useEffect(() => {
    // Add a dateClick event listener to the FullCalendar component
    if (calendarRef.current) {
      calendarRef.current.getApi().on("dateClick", handleDateClick);
    }
  }, []);

  const handleCalenderDateChange = (newDate) => {
    setDate(newDate);
    console.log(newDate);
    console.log(date);
  };

  return (
    <div className="acdClndr">
      <div className="acdiv1">
        <div className="acd1d1">
          <span>Academic Calendar</span>
          <div className="acd1d2">
            <MonthSelect
              calendarRef={calendarRef}
              setDate={setDate}
              date={date}
            />
            &nbsp; &nbsp; &nbsp;
            <YearSelect
              calendarRef={calendarRef}
              setDate={setDate}
              date={date}
            />
          </div>
        </div>
      </div>
      <div className="acdiv2">
        {/*                 <Calendar onChange={handleCalenderDateChange} activeStartDate={date} value={date} />
         */}{" "}
        <FullCalendar
          ref={calendarRef}
          headerToolbar={false}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          height={340}
          selectable={true}
          date={date}

          eventDidMount={(info) => {
            return new Bootstrap.Popover(info.el, {
              title: info.event.title,
              placement: "auto",
              trigger: "hover",
              customClass: "popoverStyle",
              content: `<p>${info.event.extendedProps.description}</p>`,
              html: true,
            });
          }}
        />
      </div>
    </div>
  );
}
