// import React, { useEffect, useState } from "react";
// // import { Calendar } from "@fullcalendar/core";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import "./CalendarBox.css";

// const events = [{ title: "Meeting", start: new Date() }];

// function renderEventContent(eventInfo) {
//   return (
//     <>
//       <b>{eventInfo.timeText}</b>
//       <i>{eventInfo.event.title}</i>
//     </>
//   );
// }

// const CalendarBox = ({ date }) => {
//   //   useEffect(() => {
//   //     const calendarEl = document.getElementById("calendar");
//   //     if (calendarEl) {
//   //       const calendar = new Calendar(calendarEl, {
//   //         plugins: [dayGridPlugin],
//   //         // themeSystem:"bootstrap",
//   //         initialView: "dayGridMonth",
//   //         // weekends: false,
//   //         events: [{ title: "Meeting", start: new Date() }],
//   //         initialDate: date,
//   //         headerToolbar: false,
//   //         themeSystem: "bootstrap5",
//   //         height: "300px",
//   //         width: "100%",
//   //       });

//   //       calendar.render();
//   //     }
//   //   }, [date]);

//   return (
//       <div id="calendar"></div>
//   );
// };

// export default CalendarBox;

import React, { useEffect } from "react";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

const CalendarBox = ({ date }) => {
  useEffect(() => {
    const calendarEl = document.getElementById("calendar");
    const calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin],
      //   initialView: "timeGridWeek",
      initialView: "dayGridMonth",
      // weekends: false,
      events: [{ title: "Meeting", start: new Date() }],
      initialDate: date,
      headerToolbar: false,
      themeSystem: "bootstrap5",
      height: "300px",
      width: "100%",
    });

    calendar.render();
  }, []); // Empty dependency array ensures that the effect runs only once after the initial render

  return <div id="calendar"></div>;
};

export default CalendarBox;
