import React from 'react'

const SchoolIcon = () => {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.14258 25.7004C0.511571 25.7004 0 26.2119 0 26.843V37.8575C0 38.4884 0.511571 39.0001 1.14258 39.0001H5.05782V25.7004H1.14258Z" fill="url(#paint0_linear_1296_5948)"/>
    <path d="M37.8574 25.7004H33.9422V39.0001H37.8574C38.4884 39.0001 39 38.4884 39 37.8575V26.843C39 26.2119 38.4884 25.7004 37.8574 25.7004Z" fill="url(#paint1_linear_1296_5948)"/>
    <path d="M33.435 13.5646L20.6427 7.74974V3.50894H24.3954C25.0264 3.50894 25.5379 2.99737 25.5379 2.36636V1.14258C25.5379 0.511571 25.0264 0 24.3954 0H19.5001C18.8691 0 18.3575 0.511571 18.3575 1.14258V7.74974L5.56526 13.5646C4.99078 13.8257 4.73674 14.503 4.99794 15.0775C5.25905 15.652 5.93653 15.9059 6.51086 15.6448L7.34312 15.2665V37.8575C7.34312 38.4884 7.85469 39.0001 8.4857 39.0001H14.6861V30.5145C14.6861 29.8836 15.1977 29.3719 15.8287 29.3719H23.1715C23.8025 29.3719 24.3141 29.8836 24.3141 30.5145V39.0001H30.5145C31.1455 39.0001 31.6571 38.4884 31.6571 37.8575V15.2665L32.4893 15.6449C32.6427 15.7146 32.8033 15.7476 32.9614 15.7476C33.3957 15.7476 33.8108 15.4986 34.0023 15.0776C34.2634 14.503 34.0094 13.8257 33.435 13.5646ZM19.5001 24.4011C16.8456 24.4011 14.6861 22.2415 14.6861 19.5871C14.6861 16.9326 16.8457 14.7731 19.5001 14.7731C22.1545 14.7731 24.3141 16.9326 24.3141 19.5871C24.3141 22.2415 22.1546 24.4011 19.5001 24.4011Z" fill="url(#paint2_linear_1296_5948)"/>
    <defs>
        <linearGradient id="paint0_linear_1296_5948" x1="2.52891" y1="25.7004" x2="2.52891" y2="39.0001" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F7F5F6"/>
            <stop offset="1" stop-color="#86878C"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1296_5948" x1="36.4711" y1="25.7004" x2="36.4711" y2="39.0001" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F7F5F6"/>
            <stop offset="1" stop-color="#86878C"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1296_5948" x1="19.5001" y1="0" x2="19.5001" y2="39.0001" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F7F5F6"/>
            <stop offset="1" stop-color="#86878C"/>
        </linearGradient>
    </defs>
    </svg>
  )
}

export default SchoolIcon