import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import TeacherAssignment from "../Assignment/TeacherAssignment";
import TeacherHomework from "../Homework/TeacherHomework";
import TeacherExam from "../Exam/TeacherExam";
import TeacherMail from "../Mail/TeacherMail";
import TeacherHome from "../Home/TeacherHome"
import TeacherSubject from "../Subjects/TeacherSubject";
import TeacherStudent from "../Student/TeacherStudent";
import TeacherNotice from "../Notice/TeacherNotice"
import Layout from "./Layout";
import ExamMarks from "../Exam/ExamMarks/ExamMarks";
import ClassTestMarks from "../Exam/ClassTestMarks/ClassTestMarks";
import StudentAttendance from "../Student/StudentAttendance/StudentAttendance";
import Profile from "../TeacherProfile/Profile";
import FolderTable from "../Exam/PaperFolder/FolderTable";

function Routes() {
  return (
    <>
      <Router>
        <Route render={(props)=>(
            <Layout {...props}>
                <Switch>
                    <Route exact path="/teacher/home" component={TeacherHome} />
                    <Route exact path="/teacher/subject" component={TeacherSubject} />
                    <Route exact path="/teacher/add-assignment" component={TeacherAssignment} />
                    <Route exact path="/teacher/add-homework" component={TeacherHomework} />
                    <Route exact path="/teacher/student-attendance" component={StudentAttendance}/>
                    <Route exact path="/teacher/student-profile" component={TeacherStudent} />
                    <Route exact path="/teacher/mail" component={TeacherMail} />
                    {/* <Route exact path="/teacher/exam" component={TeacherExam} /> */}
                    <Route exact path="/teacher/notice" component={TeacherNotice} />
                    <Route exact path="/teacher/exam-marks" component={ExamMarks} />
                    <Route exact path="/teacher/class-test-marks" component={ClassTestMarks}/>
                    <Route exact path="/teacher/upload-paper" component={TeacherExam} />
                    <Route exact path="/teacher/question-paper" component={FolderTable} />
                    <Route exact path="/teacher/home/profile" component={Profile} />
                    <Redirect to="/teacher/home" />
                </Switch>
            </Layout>
        )}/>
      </Router>
    </>
  );
}

export default Routes;
