import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Input } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import StatsBox from "./StatsBox";
import "./Information.css";
import { height, minWidth } from "@mui/system";
import CircularsList from "./CircularsList";
import SchoolUsersService from "../../../services/schoolusers.service";
import { useSelector } from "react-redux";
import CalendarBox from "./CalendarBox";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router-dom"; // Import useHistory for v5




function Information() {
  const { user } = useSelector((state) => state.user);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalTeachers, setTotalTeachers] = useState(0);
  const [totalStaff, setTotalStaff] = useState(0);
  const [studentGenderCounts, setStudentGenderCounts] = useState(0);
  const [newlyAddedUsersArray, setNewlyAddedUsersArray] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [options, setOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory(); // Initialize useHistory instead of useNavigate


  useEffect(() => {
    SchoolUsersService.getTotalStudentsNumber(user?.schoolcode).then((res) =>
      setTotalStudents(res.totalStudentsNumber)
    );

    SchoolUsersService.getTotalTeachersNumber(user?.schoolcode).then((res) =>
      setTotalTeachers(res.totalTeachersNumber)
    );

    SchoolUsersService.getTotalStaffNumber(user?.schoolcode).then((res) =>
      setTotalStaff(res.totalStaffNumber)
    );

    SchoolUsersService.getStudentGenderCounts(user?.schoolcode).then((res) => {
      setStudentGenderCounts(res);
    });

    SchoolUsersService.newlyAddedUsers(user?.schoolcode).then((res) => {
      const updatedUsersArray = res.map((user) => ({
        imgSrc: user.profileImage || "",
        firstName: user.firstname,
        lastName: user.lastname,
        designation: user.userType,
        dateOfJoining: user.dateOfJoining,
      }));

      setNewlyAddedUsersArray([...updatedUsersArray]);
    });
  }, [user]);

  const [deviceSize, setDeviceSize] = useState("pc");

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 640) {
        setDeviceSize("sm");
      } else if (screenSize < 720) {
        setDeviceSize("md");
      } else if (screenSize < 1024) {
        setDeviceSize("xl");
      } else if (screenSize < 1280) {
        setDeviceSize("2xl");
      } else {
        setDeviceSize("pc");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chartData = [
    {
      id: 0,
      value: parseInt(studentGenderCounts.male, 10),
      label: "Male",
      color: "#4B7BEC",
    },
    {
      id: 1,
      value: parseInt(studentGenderCounts.female, 10),
      label: "Female",
      color: "#D980FA",
    },
    {
      id: 2,
      value: parseInt(studentGenderCounts.others, 10),
      label: "Others",
      color: "#F6B93B",
    },
  ];

  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "Novermber",
    "December",
  ];

  const yearArray = [];
  for (let year = 1951; year <= 2050; year++) {
    yearArray.push(year.toString());
  }

  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const doubleDigit = (monthNo) => {
    if (monthNo < 10) {
      return `0${monthNo}`;
    } else return monthNo;
  };

  const formattedCurrentDate = `${currentYear}-${doubleDigit(
    currentMonth + 1
  )}-01`;
  const [initialDate, setInitialDate] = useState(formattedCurrentDate);
  useEffect(() => {
    setInitialDate(`${year}-${doubleDigit(parseInt(month) + 1)}-01`);
  }, [month, year]);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }


  // Debounced function for API call
  const debouncedFetch = debounce(async (value) => {
    if (value) {
      try {
        const response = await axios.get(
          `https://api.poweredu.in/api/schoolusers/listofid/${value}`
        );
        
        // Map API response to display options
        const formattedOptions = response.data.userIds.map((id) => id);
        setOptions(formattedOptions);
        setShowDropdown(true); // Show dropdown when there are results
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      setShowDropdown(false); // Hide dropdown if input is empty
    }
  }, 500); // 500ms debounce time

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchString(value);
    debouncedFetch(value); // Call the debounced fetch
  };

  const handleOptionClick = (option) => {
    console.log(option,"optiondetails")
    setSearchString(option); // Set the selected option as the input value
    setShowDropdown(false); // Hide dropdown after selection
    console.log(`Selected: ${option}`);

    history.push({
      pathname: "/admin/edit-profile",
      userId: option.user_id, 
      userType: option.userType 
    });


    // navigate(`/admin/edit-profile`, { state: { user_id: option.user_id,userType:option.userType } })
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* Search Boxes */}
      <div
        style={{
          display: "grid",

          gridTemplateColumns: `${deviceSize === "sm" ? "auto" : "auto 1fr"}`,

          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            backgroundColor: "white",
            borderRadius: "8px",
            alignItems: "center",
            // border: "2px solid #404040",
            boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.42)",
            padding: "5px",
          }}
        >
          <SearchRoundedIcon />
          <div style={{ position: "relative", width: "100%" }}>
      {/* Input for search */}
      <input
        type="text"
        value={searchString}
        onChange={handleChange}
        placeholder="Search by Rekor Id"
        style={{
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: "300",
          border: "none",
          outline: "none",
          width: "100%",
          padding: "8px",
        }}
      />

      {/* Dropdown for showing search results */}
      {showDropdown && options.length > 0 && (
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
            margin: 0,
            position: "absolute",
            width: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            maxHeight: "150px",
            overflowY: "auto",
            zIndex: 1,
          }}
        >
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderBottom: "1px solid #ddd",
              }}
            >
              {option.user_id}
            </li>
          ))}
        </ul>
      )}
    </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            backgroundColor: "white",
            borderRadius: "8px",
            alignItems: "center",
            // border: "2px solid #404040",
            boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.42)",
            padding: "5px",
          }}
        >
          <SearchRoundedIcon />
          <input
            type="text"
            placeholder="Search Student, Teacher, and Employee"
            style={{
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: "300",
              textDecoration: "none !important",
              border: "none",
              outline: "none",
              width: "100%",
            }}
          />
        </div>
      </div>

      {/* Stat Boxes */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit,minmax(150px,1fr))",
          gap: "20px",
        }}
      >
        <StatsBox text="Total Students" value={totalStudents} />
        <StatsBox text="Total Teachers" value={totalTeachers} />
        <StatsBox text="Total Staff" value={totalStaff} />
        <StatsBox text="Upcoming Event" value="Diwali 13-08-2021" />
      </div>

      {/* Lists */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: `${deviceSize === "pc" ? "row" : "column"}`,
          gap: `${deviceSize === "pc" ? "3%" : "20px"}`,
        }}
      >
        {/* List of new users Box*/}
        <div
          style={{
            height: "360px",
            width: `${deviceSize === "pc" ? "57%" : "100%"}`,
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            // border: "1px solid #C4C4C4",
            boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.42)",
          }}
        >
          {/* top heading box */}
          <div
            style={{
              width: "100%",
              height: "60px",
              backgroundColor: "#F9F9F9",
              borderRadius: "9px 9px 0px 0px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontFamily: "Poppins, sans-serif",
                fontSize: `${deviceSize === "sm" ? "18px" : "20px"}`,
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  color: "#000000",
                }}
              >
                Newly Added User's
              </div>
              <div style={{ fontWeight: "400", color: "#707070" }}>
                (Upto 10)
              </div>
            </div>
          </div>

          <div
            style={{
              height: "300px",
              display: "flex",
              flexDirection: "column",
              paddingTop: `${deviceSize === "sm" ? "0px" : "15px"}`,
              borderRadius: "0px 0px 10px 10px",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontFamily: "Poppins",
                fontSize: `${deviceSize === "sm" ? "3.5vw" : "18px"}`,
                fontWeight: "600",
                padding: `${deviceSize === "sm" ? "0px 10px" : "0px 20px"}`,
              }}
            >
              <p
                style={{
                  width: `${
                    deviceSize === "pc"
                      ? "225px"
                      : deviceSize === "sm"
                      ? "48%"
                      : "50%"
                  }`,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Name
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: "1",
                  gap: "0.8vw",
                }}
              >
                <p
                  style={{
                    width: `${deviceSize === "sm" ? "60px" : "100px"}`,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Designation
                </p>
                <p
                  style={{
                    width: `${deviceSize === "sm" ? "17vw" : "140px"}`,
                  }}
                >
                  Date of Joining
                </p>
              </div>
            </div>

            {/* List of new users */}
            <div
              className="custom-scrollbar"
              style={{
                height: "80%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                padding: `${deviceSize === "sm" ? "0px 10px" : "0px 20px"}`,
                borderRadius: "0px 0px 10px 10px",
                background: "white",
              }}
            >
              {newlyAddedUsersArray.map((user, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    fontFamily: "Roboto",
                    fontSize: `${deviceSize === "sm" ? "3.2vw" : "18px"}`,
                    fontWeight: "400",
                    padding: "4px 0px",
                  }}
                >
                  <div
                    style={{
                      width: `${
                        deviceSize === "pc"
                          ? "225px"
                          : deviceSize === "sm"
                          ? "50%"
                          : "50%"
                      }`,
                      display: "flex",
                      alignItems: "center",
                      gap: `${deviceSize === "sm" ? "10px" : "20px"}`,
                    }}
                  >
                    {user.imgSrc !== "" ? (
                      <img
                        src={user.imgSrc}
                        alt="Profile Pic"
                        style={{
                          width: `${deviceSize === "sm" ? "45px" : "54px"}`,
                          height: `${deviceSize === "sm" ? "45px" : "54px"}`,
                          borderRadius: "50px",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundColor: "gray",
                          width: `${deviceSize === "sm" ? "45px" : "54px"}`,
                          height: `${deviceSize === "sm" ? "45px" : "54px"}`,
                          borderRadius: "50px",
                        }}
                      ></div>
                    )}

                    <div>{`${user.firstName} ${user.lastName}`}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        width: `${deviceSize === "sm" ? "45px" : "100px"}`,
                      }}
                    >
                      {user.designation}
                    </div>
                    <div
                      style={{
                        width: `${deviceSize === "sm" ? "15vw" : "125px"}`,
                      }}
                    >
                      {user.dateOfJoining}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Circulars List Box*/}
        <CircularsList />
      </div>

      {/* Chart & Calender */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          gap: "20px",
        }}
      >
        {/* Pie Chart of gender composition */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // width: "500px",
            // height: "500px",
            borderRadius: "10px",
            // border: "1px solid #E7E7E7",
            boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.42)",
          }}
        >
          {/* top heading */}
          <div
            style={{
              width: "100%",
              height: "58px",
              backgroundColor: "#F9F9F9",
              borderRadius: "9px 9px 0px 0px",
              display: "flex",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "30px",
              paddingLeft: "20px",
            }}
          >
            Students's Ratio
          </div>

          {/* bottom area */}
          <div
            style={{
              height: "300px",
              backgroundColor: "white",
              position: "relative", // Set position to relative for absolute positioning
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <PieChart
              series={[
                {
                  data: chartData,
                  innerRadius: 60,
                  outerRadius: 100,
                  paddingAngle: 0,
                  cornerRadius: 0,
                  startAngle: 0,
                  endAngle: 360,

                  cx: 150,
                  cy: 150,
                },
              ]}
              legend={{ hidden: true }}
            />

            {/* Labels positioned at the top right corner */}
            <div
              style={{
                position: "absolute",
                top: 10, // Adjust top position as needed
                right: 10, // Adjust right position as needed
              }}
            >
              {chartData.map((item) => (
                <div
                  key={item.id}
                  style={{ display: "flex", gap: "4px", alignItems: "center" }}
                >
                  <div
                    style={{
                      height: "14px",
                      width: "14px",
                      borderRadius: "50px",
                      backgroundColor: item.color,
                    }}
                  ></div>
                  <div>{item.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Calender Box */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "600px",
            // minHeight:"500px",
            // backgroundColor: "yellow",
            borderRadius: "10px",
            // border: "1px solid #E7E7E7",
            boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.45)",
          }}
        >
          {/* top heading */}
          <div
            style={{
              width: "100%",
              height: "58px",
              backgroundColor: "#F9F9F9",
              borderRadius: "9px 9px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 20px",
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "30px",
              }}
            >
              Event Calender
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* month selector */}
              <select
                name="month"
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                value={month}
                style={{
                  outline: "none",
                  cursor: "pointer",
                  backgroundColor: "#E0DDDD",
                  textAlign: "center",
                }}
              >
                {monthArray.map((month, index) => (
                  <option key={index} value={index}>
                    {month}
                  </option>
                ))}
              </select>

              {/* Year selector */}
              <select
                name="month"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                value={year}
                style={{
                  outline: "none",
                  cursor: "pointer",
                  backgroundColor: "white",
                  textAlign: "center",
                  maxHeight: "100px",
                }}
              >
                {yearArray.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* bottom area */}
          <div
            style={{
              minHeight: "300px",
              backgroundColor: "white",
              position: "relative",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <CalendarBox date={initialDate} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
