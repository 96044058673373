import React, { useState } from "react";
import "./Chapterdesc.css";
import { Tab, Tabs } from "react-bootstrap";
import styles from "../StudentSubject.module.css";
import CommonService from "../../../../services/common.service";
import { toast } from "react-toastify";

export default function Chapterdesc({ chapterDesc }) {
  const [selectedKey, setSelectedKey] = useState("1");

  const onChange = (key) => {
    setSelectedKey(key);
  };

  // Download note by public folder path
  const downloadNote = async (filePath) => {
    try {
      const file = await CommonService.downloadFileByPath({ filePath });
      CommonService.downloadFileByBytes(file, filePath);
    } catch (err) {
      console.log("Problem in Chapterdesc :: downloadNote() => ", err);
    }
  };

  // Open link in new tab
  const newTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  //download file because we are getting url
  const handleDownload = (noteUrl) => {
    if (!noteUrl) {
      toast("No File Found");
      return;
    }
    window.location.href = noteUrl;
  };

  return (
    <div className={styles.tabsdiv}>
      <div className={styles.tabsdivd1}>
        <h2>{chapterDesc?.chaptername}</h2>
      </div>
      <Tabs
        activeKey={selectedKey}
        onSelect={onChange}
        className="react-bootstrap-tabs"
      >
        <Tab eventKey="1" title="Notes" className="tabCls">
          {selectedKey === "1" && (
            <>
              {chapterDesc?.notes?.length > 0 ? (
                chapterDesc.notes.map((note) => (
                  <div id={note?.id} className={styles.tab1} key={note?.id}>
                    <h4>{note?.description}</h4>
                    {console.table(note)}
                    <button onClick={() => handleDownload(note?.url)}>
                      Download
                    </button>
                  </div>
                ))
              ) : (
                <p className={styles.div2h2}>No notes available!</p>
              )}
            </>
          )}
        </Tab>
        <Tab eventKey="2" title="Links">
          {selectedKey === "2" && (
            <>
              {chapterDesc?.links?.length > 0 ? (
                chapterDesc.links.map((link) => (
                  <div id={link?.id} className={styles.tab1} key={link?.id}>
                    <h4>{link?.description}</h4>
                    <button onClick={() => newTab(link?.url)}>
                      Click Here
                    </button>
                  </div>
                ))
              ) : (
                <p className={styles.div2h2}>No links available!</p>
              )}
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );
}
