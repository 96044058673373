import Routes from "../Sidebar/Routes"

function AdminLayout() {
    return (
        <div>
            <Routes/>
        </div>
    )
}

export default AdminLayout
