import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./feedbackform.css";
import { useDispatch, useSelector } from "react-redux";
import { submitFeedback, updateFeedback } from "../../../slices/feedbackresponses";

const FeedbackForm = ({ show, onHide, feedbackForm }) => {
  const { user } = useSelector(state => state.user)
  const { feedbackresponses } = useSelector((state) => state.feedbackresponse);
  const [responseId, setResponseId] = useState(null)
  const [responses, setResponses] = useState([]);
  const [comment, setComment] = useState('');

  const initialState = () => {
    const subReponses = feedbackresponses.find(
      (fr) => fr.user_id === user.id && fr.question_id === feedbackForm?.id 
    );
    // console.log(subReponses)
    setResponseId(subReponses?.id || null)
    console.log(responseId)
    setResponses(subReponses?.responses || [])
    setComment(subReponses?.comment || '')
  }

  useEffect(() => {
    initialState()
  }, [feedbackForm])

  const dispatch = useDispatch()
  // console.log(responses)
  // useEffect(() => {
  //   setResponses(submittedResponses?.responses || [])
  //   setComment(submittedResponses?.comment)
  // }, [submittedResponses])

  const handleRatingChange = (e, index) => {
    const updatedResponses = [...responses];
    updatedResponses[index] = e.target.value;
    setResponses(updatedResponses);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (responseId !== null) {
      // Feedback already submitted, so update it
      console.log(responseId)
      dispatch(
        updateFeedback({
          response_id: responseId,
          responses,
          comment,
        })
      );
    } else {
      // Submit new feedback
      dispatch(
        submitFeedback({
          user_id: user.id,
          question_id: feedbackForm.id,
          teacher_id: feedbackForm.teacher_id,
          responses,
          comment,
        })
      );
    }
    onHide();
    // setComment('');
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="feedback-model"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <Form id="feedback-form" onSubmit={handleSubmit}>
          {feedbackForm?.questions.map((question, index) => (
            <div key={index} className="feedback-question">
              <h2 className="feedback-question-item">
                {question || `How is your overall experience?`}
              </h2>
              <div className="radio-group">
                {Array.from({ length: feedbackForm?.ranges[index] }, (_, i) => String(i + 1)).map((rating) => (
                  <label className="radio-label" key={rating}>
                    <input
                      type="radio"
                      className="radio-item"
                      name={`question${index + 1}`}
                      value={rating}
                      checked={responses && rating === responses[index]}
                      onChange={(e) => handleRatingChange(e, index)}
                      required
                    />
                    {rating}
                  </label>
                ))}
              </div>
            </div>
          ))}
          <div className="feedback-question">
            <h2 className="feedback-question-item">Other comment</h2>
            <textarea
              className="comment-box"
              name="comment"
              rows="4"
              placeholder="Type here"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            // required
            />
          </div>
        </Form>
      </Modal.Body>
      <div className="feedback-footer">
        <button className="cancel-btn" onClick={() => onHide() & initialState()}>
          Cancel
        </button>
        <button
          className="submit-btn"
          form="feedback-form"
        // onClick={handleSubmit}
        // disabled={responses.length < formlength}
        >
          {responseId ? "Update" : "Submit"}
        </button>
      </div>
    </Modal>
  );
};

export default FeedbackForm;
