import React, { useEffect, useState } from "react";
import "./Profiles.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import WestIcon from "@mui/icons-material/West";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ProfileTable from "./ProfileTable";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


import {
  fetchAllAccountant,
  fetchAllPrincipal,
  fetchAllStaff,
  fetchAllTeachers,
  getAcademicYearsDropdown,
} from "../../../../slices/admin";
import { fetchAllStudents } from "../../../../slices/student";
import { getDropdownClasses } from "../../../../slices/principal";
import useDebounce from "../../../../Utils/debounce";
import StudentService from "../../../../services/student.service";
import SchoolUsersService from "../../../../services/schoolusers.service";

function Profiles() {
  const location = useLocation();
  const { locationName } = location;
  let profileType = locationName.toLowerCase();

  const { user: currentUser } = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms debounce delay
  const { allStudents, total: totalStudents } = useSelector((state) => state.student);


  const { 
    allTeachers, 
    total: totalTeachers,       // Assuming you have a total count for teachers
    allPrincipal, 
    total: totalPrincipal,      // Assuming you have a total count for principals
    allAccountant, 
    total: totalAccountant,     // Assuming you have a total count for accountants
    allStaff, 
    total: totalStaff           // Assuming you have a total count for staff
  } = useSelector((state) => state.admin);
  // const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms debounce delay

  
  const { classes } = useSelector((state) => state.principal);
  const { academicYearsDropdown } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const getCurrentAcademicYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    return currentMonth > 6 ? currentYear : currentYear - 1;
  };

  const [classFilter, setClassFilter] = useState("1A");
  const [academicYearFilter, setAcademicYearFilter] = useState(
    getCurrentAcademicYear()
  );

  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(5);


  let total;
  if (profileType === "students") {
    total = totalStudents;
  } else if (profileType === "teachers") {
    total = allTeachers.length;
  } else if (profileType === "principal") {
    total = allPrincipal.length;
  } else if (profileType === "accountant") {
    total = allAccountant.length;
  } else if (profileType === "staff") {
    total = allStaff.length;
  } else {
    total = 0;
  }


  const handleSearchAPI = async () => {
    if (profileType === "students") {
      try {
        const body = {
          school_code: currentUser.schoolcode,
          class_code: classFilter,
          year: parseInt(academicYearFilter),
          searchTerm: debouncedSearchTerm,
        };
        console.log("beforeresultworks")
        const result = await StudentService.searchStudents(body);
        console.log(result.students,"resultconsole57457")
        setFilteredUsers(result); // Update filtered users
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    }
    else if (profileType === "teachers") {
      try {
        const body = {
          school_code: currentUser.schoolcode,
          class_code: classFilter,
          year: parseInt(academicYearFilter),
          searchTerm: debouncedSearchTerm,
        };
        const result = await SchoolUsersService.getAllTeachersByYearSearch(body);
        setFilteredUsers(result); // Update filtered users
      } catch (error) {
        console.error("Error fetching students:", error);
      }   
  }
  else if (profileType === "principal") {
    try {
      const body = {
        school_code: currentUser.schoolcode,
        year: parseInt(academicYearFilter),
        profile: "principal",
        searchTerm: debouncedSearchTerm,
      };
      const result = await SchoolUsersService.getAllOtherManagementMembersByYearSearch(body);
      setFilteredUsers(result); // Update filtered users
    } catch (error) {
      console.error("Error fetching students:", error);
    }   
  }
  else if (profileType === "accountant") {
    try {
      const body = {
        school_code: currentUser.schoolcode,
        year: parseInt(academicYearFilter),
        profile: "accountant",
        searchTerm: debouncedSearchTerm,
      };
      const result = await SchoolUsersService.getAllOtherManagementMembersByYearSearch(body);
      setFilteredUsers(result); // Update filtered users
    } catch (error) {
      console.error("Error fetching students:", error);
    }   
  }
  else if (profileType === "staff") {
    try {
      const body = {
        school_code: currentUser.schoolcode,
        year: parseInt(academicYearFilter),
        profile: "staff",
        searchTerm: debouncedSearchTerm,
      };
      const result = await SchoolUsersService.getAllOtherManagementMembersByYearSearch(body);
      setFilteredUsers(result); // Update filtered users
    } catch (error) {
      console.error("Error fetching students:", error);
    }   
  }
}


  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearchAPI();
    }
  }, [debouncedSearchTerm]);

  const handleApplyFilter = () => {
    if (profileType === "students") {
      dispatch(
        fetchAllStudents({
          school_code: currentUser.schoolcode,
          class_code: classFilter,
          year: parseInt(academicYearFilter),
          // profile: "student",
          page,
          limit,
        })
      );
    } else if (profileType === "teachers") {
      dispatch(
        fetchAllTeachers({
          school_code: currentUser.schoolcode,
          year: parseInt(academicYearFilter),
          profile: "teacher",
          page,
          limit,
        })
      );
    } else if (profileType === "principal") {
      dispatch(
        fetchAllPrincipal({
          school_code: currentUser.schoolcode,
          year: parseInt(academicYearFilter),
          profile: "principal",
          page,
          limit,
        })
      );
    } else if (profileType === "accountant") {
      dispatch(
        fetchAllAccountant({
          school_code: currentUser.schoolcode,
          year: parseInt(academicYearFilter),
          profile: "accountant",
          page,
          limit,       
        })
      );
    } else if (profileType === "staff") {
      dispatch(
        fetchAllStaff({
          school_code: currentUser.schoolcode,
          year: parseInt(academicYearFilter),
          profile: "staff",
          page,
          limit,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getDropdownClasses({ schoolcode: currentUser.schoolcode }));
    dispatch(getAcademicYearsDropdown({ schoolcode: currentUser.schoolcode }));
    handleApplyFilter(); // Fetch the data based on initial filters
  }, [currentUser.schoolcode, dispatch]);


  useEffect(() => {
    handleApplyFilter();
  }, [page]);
  
  useEffect(() => {
    if (profileType === "students") {
      setAllUsers(allStudents);
    } else if (profileType === "teachers") {
      setAllUsers(allTeachers);
    } else if (profileType === "principal") {
      setAllUsers(allPrincipal);
    } else if (profileType === "accountant") {
      setAllUsers(allAccountant);
    } else if (profileType === "staff") {
      setAllUsers(allStaff);
    }
  }, [allStudents, allTeachers, allPrincipal, allAccountant, allStaff, profileType]);

  useEffect(() => {
    setFilteredUsers(allUsers);
  }, [allUsers]);




  // useEffect(() => {
  //   // Perform the API call only when debouncedSearchTerm changes
  //   if (debouncedSearchTerm) {
  //     const searchSubjectsAPI = async () => {
  //       try {
  //         const body = {
  //           school_code: user?.schoolcode,
  //           searchTerm: debouncedSearchTerm,
  //         };
  //         const result = await SubjectService.getSearchSubjectsOfClasses(body);
  //         setFilteredUsers(result); // Update the filtered subjects with API response
  //       } catch (error) {
  //         console.error("Error fetching subjects:", error);
  //       }
  //     };

  //     searchSubjectsAPI();
  //   }
  // }, [debouncedSearchTerm, user]);

  // const searchUsers = (event) => {
  //   const searchValue = event.target.value.toLowerCase();
  //   const filteredResults = allUsers?.filter((user) =>
  //     user.user_id.toString().includes(searchValue)
  //   );
  //   setFilteredUsers(filteredResults);
  // };

  const handlePageChange = (event, value) => {
    console.log(value,"vlaueofpage")
    setPage(value);
  };

  // const handleApplyFilter = () => {
  //   let fetchFunction;
  //   const commonParams = {
  //     school_code: currentUser.schoolcode,
  //     year: parseInt(academicYearFilter),
  //     page,
  //     limit,
  //   };

  return (
    <div className="studentattendance">
      <nav
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "20px",
        }}
      >
        {/* left area */}
        <div
          style={{ color: "#4D4D4D", display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "400",
            }}
          >
            Home
          </div>
          <KeyboardArrowRightIcon />
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "700",
              textDecoration: "underline",
            }}
          >
            {profileType === "students"
              ? "Students"
              : profileType === "teachers"
              ? "Teachers"
              : profileType === "principal"
              ? "Principal"
              : "Staff"}
          </div>
        </div>

        {/* right area */}
        <Link to="./home" style={{ textDecoration: "none" }}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <WestIcon style={{ color: "#5F5F5F" }} />
            <div
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "24px",
                letterSpacing: "4%",
                color: "#414141",
              }}
            >
              Back
            </div>
          </div>
        </Link>
      </nav>
      <div>
        <h3
          style={{
            fontFamily: "Poppins",
            fontWeight: "600",
            marginTop: "30px",
            fontSize: "25px",
          }}
        >
          {`${
            profileType === "students"
              ? "Student"
              : profileType === "teachers"
              ? "Teacher"
              : profileType === "principal"
              ? "Principal"
              : "Staff"
          } Details`}
        </h3>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <div className="filtersContainer">
          <p
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "20px",
              color: "#000000",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Filters:-
          </p>
          <div className="filters">
            <select
              value={academicYearFilter}
              onChange={(e) => {
                setAcademicYearFilter(e.target.value);
              }}
              style={{
                borderRadius: "5px",
                fontSize: "17px",
                padding: "4px 10px",
                color: "#414141",
              }}
            >
              {academicYearsDropdown?.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </select>

            {profileType === "students" && (
              <select
                value={classFilter}
                onChange={(e) => setClassFilter(e.target.value)}
                style={{
                  borderRadius: "5px",
                  fontSize: "17px",
                  padding: "4px 10px",
                  color: "#414141",
                }}
              >
                {classes?.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </select>
            )}
            <button
              className="stdntAttndnceApplyBtn"
              onClick={handleApplyFilter}
            >
              Apply
            </button>
          </div>
        </div>
        {profileType === "principal" ? (
          // <div
          //   style={{
          //     width: "183px",
          //     height: "40px",
          //     border: "1px solid #214DF9",
          //     color: "#214DF9",
          //     display: "grid",
          //     placeContent: "center",
          //     fontFamily: "Rubik",
          //     fontSize: "18px",
          //     fontWeight: "500",
          //     borderRadius: "5px",
          //   }}
          // >
          //   + Add New User
          // </div>
          <div className="searchBar">
          <SearchRoundedIcon />
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
            placeholder="Search by Rekor Id"
          />
        </div>
        ) : (
          <div className="searchBar">
            <SearchRoundedIcon />
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
              placeholder="Search by Rekor Id"
            />
          </div>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
        <ProfileTable 
        profileType={profileType} 
        allUsers={filteredUsers}
        page={page}
        limit={limit}
        total={allUsers.count}
        onPageChange={handlePageChange} />
      </div>
    </div>
  );
}

export default Profiles;
