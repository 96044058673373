import axios from "axios";
const Api_Url = "https://api.poweredu.in/api/";

const getimageUrl = async (path) => {
    const res = await axios.post(`${Api_Url}profile/getimg`, { path: path.toString() });
    return res;
}

const imageService = {
    getimageUrl
}

export default imageService;