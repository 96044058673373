import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css';
import { ToastContainer, toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

function Password(props) {
  const RekorId = props.RekorId;
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const history = useHistory();
  const handlePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handlePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const [formInput, setFormInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [formError, setFormError] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleUserInput = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const validateFormInput = async (event) => {
    event.preventDefault();
    let inputError = {
      password: "",
      confirmPassword: "",
    };

    if (!formInput.password && !formInput.confirmPassword) {
      setFormError({
        ...inputError,
        password: "Password should not be empty",
        confirmPassword: "Password should not be empty",
      });
      return
    }  else if (formInput.password.length < 8) {
      setFormError({
        ...inputError,
        password : "Password must be at least 8 characters long",
      });
      return
     
    }
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formInput.password)) {
      setFormError({
        ...inputError,
        password : "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      });
      return
    }

    if (formInput.confirmPassword !== formInput.password) {
      setFormError({
        ...inputError,
        confirmPassword: "Password and confirm password should be same",
      });
      return;
    }

    const body = {
      "userId" : RekorId,
      "password" : formInput.password
    }
    
    await axios.put("https://api.poweredu.in/api/auth/password",body)
    .then((response) => {
        console.log(response.data.message)
        toast.success("Password updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => {
          history.push("/");
        }, 1500);   
    }, (error) => {
        console.log(error.data.message)
    })
    // else {
      // history.push("/");
    // }
  };

  return (
    <div>
    <ToastContainer />
    <div className="Main password">
      <div>
        <h1 className='heading'>Create Password</h1>
        <form className="passform" onSubmit={validateFormInput}>
          <label className="passlabel">Create New Password</label>
          <div className="passdiv">
            <input
              type={showPassword1 ? "text" : "password"}
              name="password"
              className="forInput"
              value={formInput.password}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
            <button
              type="button"
              className="pass_btn"
              onClick={handlePasswordVisibility1}
            >
              <FontAwesomeIcon icon={showPassword1 ? faEye : faEyeSlash} />
            </button>
          </div>
          <p className="errmsg">{formError.password}</p>
          <label className="passlabel">Confirm Password</label>
          <div className="passdiv">
            <input
              type={showPassword2 ? "text" : "password"}
              name="confirmPassword"
              className="forInput"
              value={formInput.confirmPassword}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
            <button
              type="button"
              className="pass_btn"
              onClick={handlePasswordVisibility2}
            >
              <FontAwesomeIcon icon={showPassword2 ? faEye : faEyeSlash} />
            </button>
          </div>
          <p className="errmsg">{formError.confirmPassword}</p>
           {formInput.password <=0 ?
                <button className="forBtn1" disabled={!formInput.password}>Save Password</button>
                :
                <button className="forBtn2">Save Password</button>
              }
        </form>
        <p className="forP2 passforP2"><a href="/"><ArrowBackIcon style={{ marginRight: "15px" }} />Back to log in screen</a></p>

      </div>
    </div>
    </div>
  )
}

export default Password;
