import axios from "axios";
const API_URL = process.env.API_URL || "https://api.poweredu.in/api/student/";

const getAllStudents = async (body) => {
  try {
    const response = await axios.post(API_URL + "all", body);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const searchStudents = async(body) => {
  try {
    const response = await axios.post(API_URL + "all/search", body);
    return response.data;

  } catch (error) {
    console.log(error);
    throw error;
  }
};



const getStudent = async (body) => {
  try {
    const { data } = await axios.post(API_URL + "details", body);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCurrentStudentData = getStudent;


const updateStudentDetails = async (body) => {
  try {
    const { data } = await axios.post(API_URL + "update", body);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteStudentProfile = async (body) => {
  try {
    const { data } = await axios.post(API_URL + "delete", body);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getStudentAcademics = async (body) => {
  try {
    const { data } = await axios.post(API_URL + "academics", body);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getStudentsWithMarks = async (body) => {
  try {
    const { data } = await axios.post(API_URL + "studentmarks", body);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getStudentFeeDetails = async (body) => {
  try {
    const { data } = await axios.post(API_URL + "studentfeedetails", body);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const StudentService = {
  getAllStudents,
  getCurrentStudentData,
  getStudent,
  updateStudentDetails,
  deleteStudentProfile,
  getStudentAcademics,
  getStudentsWithMarks,
  getStudentFeeDetails,
  searchStudents
};

export default StudentService;
