import React, { useEffect, useState } from "react";
import MeetingsList from "./MeetingsList";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CSSTransition } from "react-transition-group";
import "animate.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function MeetingsBox() {
  const [deviceSize, setDeviceSize] = useState("pc");
  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 640) {
        setDeviceSize("sm");
      } else if (screenSize < 768) {
        setDeviceSize("md");
      } else if (screenSize < 1024) {
        setDeviceSize("xl");
      } else if (screenSize < 1280) {
        setDeviceSize("2xl");
      } else {
        setDeviceSize("pc");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isCalenderOpen, setCalenderOpen] = useState(false);
  const [isMeetingsListOpen, setMeetingsListOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (isMeetingsListOpen) {
      setCalenderOpen(false);
    }
  }, [isMeetingsListOpen]);

  useEffect(() => {
    if (isCalenderOpen) {
      setMeetingsListOpen(false);
    }
  }, [isCalenderOpen]);

  function doubleDigit(number) {
    return number > 9 ? number : `0${number}`;
  }

  function formatDate(date) {
    const day = doubleDigit(date.getDate());
    const month = doubleDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: `${deviceSize === "pc" || deviceSize === "2xl" ? "60%" : "100%"
          }`,
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "white",
        boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.45)",
        position: "relative",
      }}
    >
      {/* top heading */}
      <div
        style={{
          width: "100%",
          height: `${deviceSize === "pc" || deviceSize === "2xl" ? "58px" : "100%"
            }`,
          backgroundColor: "#F9F9F9",
          borderRadius: `${deviceSize === "pc" || deviceSize === "2xl"
            ? "10px 10px 0px 0px"
            : "10px 10px 10px 10px"
            }`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `${deviceSize === "pc" || deviceSize === "sm" ? "0px 20px" : "0px 10px"}`,
        }}
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: `${deviceSize === "2xl"
              ? "16px"
              : deviceSize === "xl"
                ? "2vw"
                : deviceSize === "md"
                  ? "14px"
                  : deviceSize === "sm"
                    ? "2.8vw"
                    : "20px"
              }`,
            fontWeight: "600",
          }}
        >
          Meeting
        </div>
        <button
          style={{
            backgroundColor: "#E0DDDD",
            fontFamily: "Rubik",
            fontSize: `${deviceSize === "2xl"
              ? "1.05vw"
              : deviceSize === "xl"
                ? "1.6vw"
                : deviceSize === "md"
                  ? "12px"
                  : deviceSize === "sm"
                    ? "2.8vw"
                    : "18px"
              }`,
            fontWeight: "300",
            padding: `${deviceSize === "2xl"
              ? "4px 6px"
              : deviceSize === "xl"
                ? "2px 5px"
                : "5px 10px"
              }`,
            borderRadius: "5px",
            border: "none",
            boxShadow: "0px 1px 5px 0 rgba(33, 33, 33, 0.48)",
            position: "relative",
          }}
          onClick={() => setCalenderOpen(!isCalenderOpen)}
        >
          {formatDate(selectedDate)}
        </button>
      </div>

      {/* down arrow-- for showing/hiding Mettings list in Tab and Mobile device */}

      {deviceSize === "sm" || deviceSize === "md" || deviceSize === "xl" ? (
        <div
          style={{
            position: "absolute",
            bottom: "-5px",
            left: "40%",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <KeyboardArrowDownIcon
            onClick={() => setMeetingsListOpen(!isMeetingsListOpen)}
          />
        </div>
      ) : (
        ""
      )}

      {/* bottom area */}
      <div
        style={{
          display: `${deviceSize === "pc" || deviceSize === "2xl" ? "block" : "none"
            }`,
          backgroundColor: "white",
          height: "80%",
          width: "100%",
          overflowY: "scroll",
          padding: `${deviceSize === "2xl" ? "0px 7%" : "0px 10px"}`,
        }}
      >
        <MeetingsList date={selectedDate} />
      </div>
      <CSSTransition
        in={isMeetingsListOpen}
        timeout={500}
        classNames={{
          enter: "animate__animated animate__slideInRight",
          enterActive: "animate__animated animate__slideInRight",
          exit: "animate__animated animate__slideOutRight",
          exitActive: "animate__animated animate__slideOutRight",
        }}
        unmountOnExit
      >
        <div
          style={{
            position: "absolute",
            top: "110%",
            height: "300px",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "10px",
            zIndex: "10",
            padding: "10px",
            boxShadow: "0 2px 7px 0 rgba(52, 52, 52, 0.45)",
          }}
        >
          <div
            className="custom-scrollbar"
            style={{
              height: "100%",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            <MeetingsList date={selectedDate} />
          </div>
        </div>
      </CSSTransition>

      {isCalenderOpen && (
        <div
          style={{
            position: "absolute",
            top: `${deviceSize === "pc" || deviceSize === "2xl" ? "" : "110%"}`,
            left: `${deviceSize === "pc" || deviceSize === "2xl" ? "102%" : ""
              }`,
            right: `${deviceSize === "pc" || deviceSize === "2xl" ? "" : "0px"
              }`,
            backgroundColor: "white",
            zIndex: "10",
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <Calendar onChange={setSelectedDate} value={selectedDate} />
        </div>
      )}
    </div>
  );
}

export default MeetingsBox;
