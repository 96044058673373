import React, { useState } from 'react';
import axios from 'axios';
import OtpVerify from './otpVerify';
import './ForgotPassword.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ForgotPassword() {
  const [title, setTitle] = useState(true);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  console.log(title)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length <= 0) {
      setError(true)
    }
    else {
      const body = {
        "userId" : email
      }
      try {
        const response = await axios.post("https://api.poweredu.in/api/auth/forgetPassword", body);
        console.log(response.data);
        setTitle(false);
      } catch (error) {
        setErrorMsg(error.response.data.message)
        console.log("error in forget password function", error);
      }      
    }
  }
  return (
    <div>
      {title ?
        <div className="Main">
          <form className="Forgot_btn" onSubmit={handleSubmit}>
            <div className="forDiv1">
              <img className="forImg" width={260} height={260} src='/forget-password.svg' />
              <h1 className='heading'>Forgot Password?</h1>
              <p className="forP">No worries. We’ll send you reset instructions</p>
              <div>
                <label>PowerEdu ID</label>
                <input
                  placeholder="Enter Your PowerEdu Id"
                  className="forInput"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              {/* {error ? <p className="errmsg">Enter valid PowerEdu Id</p> : ''} */}
              {errorMsg && <p className="errmsg">{errorMsg}</p>}
              {email.length <= 0 ?
                <button className="forBtn1" disabled={!email}>Reset Password</button>
                :
                <button className="forBtn2">Reset Password</button>
              }
              <p className="forP2"><a href="/"><ArrowBackIcon style={{ marginRight: "15px" }} />Back to log in screen</a></p>
            </div>
          </form>
        </div>
        :
        <OtpVerify email = {email} />
      }
    </div>
  )
}

export default ForgotPassword
